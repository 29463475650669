import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

import Loading from '../Shared/Loading'
import Input from '../Form/Input'

import { useAuth0 } from '../../auth/auth0Provider'
import { useLoadUser, useResetPasswordUser } from '../../hooks/useUser'
import { resetAllMessages } from '../../redux/ducks/notification'
import { getUser, resetPassword } from '../../utils/api/skadi'
import { setFormError } from '../../utils/format'

const ResetPassword = ({ setIsResetForm }) => {
  const { user: auth0User } = useAuth0()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm()

  const [requestLoadUser] = useLoadUser()
  const [resetPasswordUser, userResponse] = useResetPasswordUser()

  const { profile } = useSelector((state) => state.user)

  const [newpassword, setNewPassword] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)

  useEffect(() => {
    dispatch(resetAllMessages())
  }, [dispatch])

  useEffect(() => {
    requestLoadUser(getUser(auth0User, id))
  }, [auth0User, id, requestLoadUser])

  useEffect(() => {
    if (newpassword && confirmpassword) {
      if (newpassword !== confirmpassword) {
        setConfirmPasswordError({ type: 'match' })
      } else {
        setConfirmPasswordError(false)
      }
    }
  }, [newpassword, confirmpassword, errors])

  const onSubmit = async () => {
    if (!confirmpassword) {
      setConfirmPasswordError({ type: 'required' })
    } else {
      const payload = {
        email: profile.email,
        password: newpassword
      }
      resetPasswordUser(resetPassword(auth0User, payload))
      setNewPassword('')
      setConfirmPassword('')
    }
  }

  const handleCancel = () => {
    setIsResetForm(false)
    setNewPassword('')
    setConfirmPassword('')
    setConfirmPasswordError(false)
  }

  return (
    <>
      {userResponse.state === 'loading' && <Loading />}

      <h4>Reset password for user {profile.email}</h4>

      <form className="form-container form-reset-password" onSubmit={handleSubmit(onSubmit)}>
        <Input
          className="form-input"
          label="New Password"
          name="password"
          type="password"
          value={newpassword}
          onChange={(e) => setNewPassword(e.target.value)}
          ref={register({
            required: true,
            minLength: 8,
            maxLength: 25
          })}
        />
        {errors.password && (
        <p className="form-input-error">{setFormError(errors.password, 'user')}</p>
        )}

        <Input
          className="form-input"
          label="Confirm New Password"
          name="confirmPassword"
          type="password"
          value={confirmpassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {confirmPasswordError && (
        <p className="form-input-error">{setFormError(confirmPasswordError, 'user')}</p>
        )}

        <div className="form-btn-container">
          <Button color="primary" size="lg" type="submit">
            submit
          </Button>
          <Button color="secondary" size="lg" type="button" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>

    </>
  )
}

ResetPassword.defaultProps = { setIsResetForm: () => {} }

ResetPassword.propTypes = { setIsResetForm: PropTypes.func }

export default ResetPassword
