/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import SearchEmail from '../Search/SearchEmail'
import { useAuth0 } from '../../auth/auth0Provider'

const Navbar = () => {
  const { user: auth0User, isAuthenticated, logout } = useAuth0()

  const logoutWithRedirect = () => logout({ returnTo: window.location.origin })

  const [isHover, setIsHover] = useState(false)

  const handleHover = () => {
    setIsHover(!isHover)
  }

  const { pathname } = useLocation()

  return (
    <div className="nav-menu">
      {isAuthenticated && (
        <nav className="container nav-container">
          <div className="nav-container-row">
            <div className="menu-container navbar-menu">
              <NavLink exact to="/" activeClassName="nav-active" className="menu-el">
                Home
              </NavLink>

              <NavLink exact to="/enterprises" activeClassName="nav-active" className="menu-el">
                Enterprises
              </NavLink>

              <NavLink to="/plans" activeClassName="nav-active" className="menu-el">
                Plans
              </NavLink>
            </div>

            {!pathname.includes('plans')
            && (
            <div className="menu-container navbar-menu">
              <SearchEmail />
            </div>
            )}

            <div className="menu-container navbar-menu">
              <a className="menu-account" href="#" onClick={handleHover}>
                <svg
                  className="account-icon"
                  aria-labelledby="title desc"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 17 19"
                >
                  <title id="title">Account icon</title>
                  <desc id="desc">
                    An icon in the shape of a person's head and shoulders. It often indicates a user
                    profile.
                  </desc>
                  <g className="account-icon-path" fill="none" stroke="#111" strokeWidth="2">
                    <circle cx="8.5" cy="5.5" r="4.5" />
                    <path d="M1 20h15v-.7c0-3.6-2.7-5.3-7.5-5.3S1 15.8 1 19.3v.7z" />
                  </g>
                </svg>
              </a>

              <div
                className={`menu-sub ${isHover ? 'menu-sub-visible' : ''}`}
                onMouseLeave={handleHover}
              >
                <span className="menu-sub-arrow-head" />
                <div className="menu-sub-item menu-sub-name">{auth0User.name}</div>
                <div className="menu-sub-item">
                  <NavLink to="/account" exact className="menu-sub-link">
                    Account
                  </NavLink>
                </div>
                <div className="menu-sub-item">
                  <a href="#" className="menu-sub-link" onClick={logoutWithRedirect}>
                    Log Out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}
    </div>
  )
}

export default Navbar
