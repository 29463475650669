import React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, NavItem, Col } from 'reactstrap'
import PropTypes from 'prop-types'

const EnterpriseMenu = ({ id, showAccess }) => (
  <Col lg={3} className="side-menu-container">
    <Nav vertical>
      <NavItem className="menu-container side-menu">
        <NavLink
          exact
          to={`/enterprises/${id}`}
          activeClassName="selected"
          className="menu-el"
        >
          Profile
        </NavLink>
      </NavItem>
      {showAccess && (
        <NavItem className="menu-container side-menu">
          <NavLink
            exact
            to={`/enterprises/${id}/access-page`}
            activeClassName="selected"
            className="menu-el"
          >
            Access Details
          </NavLink>
        </NavItem>
      )}
    </Nav>
  </Col>
)

EnterpriseMenu.propTypes = {
  id: PropTypes.string.isRequired,
  showAccess: PropTypes.bool
}

EnterpriseMenu.defaultProps = {
  showAccess: false
}

export default EnterpriseMenu
