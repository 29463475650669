import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import GoBack from '../../components/Shared/GoBack'
import Loading from '../../components/Shared/Loading'
import Input from '../../components/Form/Input'

import { useAuth0 } from '../../auth/auth0Provider'
import { useAddUser } from '../../hooks/useUser'
import { resetAllMessages } from '../../redux/ducks/notification'
import { createUser } from '../../utils/api/skadi'
import { setFormError } from '../../utils/format'

const CreateNewUser = () => {
  const { user: auth0User } = useAuth0()
  const history = useHistory()
  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm()

  const [requestAddUser, userResponse] = useAddUser()

  const {
    user: { profile },
    notification: {
      apiMessage: { apiSuccess, apiError }
    }
  } = useSelector((state) => state)

  const [user, setUser] = useState({})

  useEffect(() => {
    dispatch(resetAllMessages())
  }, [dispatch])

  const onSubmit = async () => {
    const data = {
      ...user,
      firstname: user.firstname || undefined,
      lastname: user.lastname || undefined
    }
    requestAddUser(createUser(auth0User, data))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setUser({
      ...user,
      [name]: value
    })
  }

  return (
    <div className="create-form-container">
      {userResponse.state === 'loading' && <Loading />}

      <GoBack />

      {apiError && <Alert color="danger">{apiError}</Alert>}

      {apiSuccess && (
        <Alert color="success">
          User {user.email} is created!{' '}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span className="user-go-btn" onClick={() => history.push(`/users/${profile.id}/profile`)}>
            Go to User
          </span>
        </Alert>
      )}

      <h2>New User</h2>

      <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
        <Input
          className="form-input"
          label="First Name"
          name="firstname"
          value={user.firstname || ''}
          placeholder="Optional"
          onChange={handleChange}
          ref={register}
        />

        <Input
          className="form-input"
          label="Last Name"
          name="lastname"
          value={user.lastname || ''}
          placeholder="Optional"
          onChange={handleChange}
          ref={register}
        />

        <Input
          className="form-input"
          label="Email"
          name="email"
          value={user.email || ''}
          placeholder="Required"
          onChange={handleChange}
          ref={register({
            required: true,
            // eslint-disable-next-line no-useless-escape
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          })}
        />
        {errors.email && <p className="form-input-error">{setFormError(errors.email, 'user')}</p>}

        <Input
          className="form-input"
          label="Password"
          name="password"
          type="password"
          value={user.password || ''}
          placeholder="Required"
          onChange={handleChange}
          ref={register({
            required: true,
            minLength: 8,
            maxLength: 25
          })}
        />
        {errors.password && <p className="form-input-error">{setFormError(errors.password, 'user')}</p>}

        <Button color="primary" size="lg" type="submit" className="form-btn-submit">
          submit
        </Button>
      </form>
    </div>
  )
}

export default CreateNewUser
