import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { reduxBatch } from '@manaflair/redux-batch'
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'
import rootReducer from './ducks'

export default (preloadedState) => {
  const loggerMiddleware = createLogger({
    predicate: () => process.env.NODE_ENV !== 'production'
  })

  // can add more middlewares in future
  const middlewares = [loggerMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [reduxBatch, middlewareEnhancer]
  const composedEnhancers = composeWithDevToolsDevelopmentOnly(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./ducks', () => store.replaceReducer(rootReducer))
  }

  return store
}
