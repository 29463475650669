import { combineReducers } from 'redux'
import { userReducer } from './user'
import { enterpriseReducer } from './enterprise'
import { notificationReducer } from './notification'
import { roleReducer } from './role'
import { userScopeReducer } from './userScope'
import { planReducer } from './plan'

export default combineReducers({
  user: userReducer,
  enterprise: enterpriseReducer,
  rbac: roleReducer,
  notification: notificationReducer,
  userScope: userScopeReducer,
  plan: planReducer
})
