import { useCallback, useReducer } from 'react'
import axios from 'axios'

export const IDLE = 'idle'
export const LOADING = 'loading'
export const SUCCESS = 'success'
export const FAILED = 'failed'

const initialState = {
  state: IDLE,
  status: null,
  error: null,
  data: null
}

const reducer = (state, action) => {
  const { payload, type } = action
  switch (type) {
    case LOADING:
      return {
        ...initialState,
        state: LOADING
      }
    case SUCCESS:
      return {
        ...initialState,
        state: SUCCESS,
        data: payload.data,
        status: payload.status
      }
    case FAILED:
      return {
        ...initialState,
        state: FAILED,
        error: payload.error,
        status: payload.status
      }
    default:
      return state
  }
}

/**
 * Hook to do HTTP request.
 */
export const useApi = () => {
  const [responseData, dispatch] = useReducer(reducer, initialState)

  const loadData = async (config) => {
    try {
      dispatch({ type: LOADING })
      const res = await axios.request(config)
      dispatch({
        type: SUCCESS,
        payload: {
          data: res.data,
          status: res.status
        }
      })
    } catch (err) {
      if (err.response) {
        const { response } = err
        const errorMessage = response.data.message || response.data.error
        dispatch({
          type: FAILED,
          payload: {
            error: errorMessage,
            status: response.status
          }
        })
      } else {
        dispatch({
          type: FAILED,
          payload: { error: err.message }
        })
      }
    }
  }

  const request = useCallback(loadData, [])

  return [request, responseData]
}
