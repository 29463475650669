import getUserActions from '../../../utils/api/getUserActions'

const types = {
  LIST_AUTH_USER_ROLES: 'LIST_AUTH_USER_ROLES',
  LIST_AUTH_USER_PERMISSIONS: 'LIST_AUTH_USER_PERMISSIONS'
}

const initialState = {
  roles: [],
  permissions: [],
  actions: {}
}

const listAuthUserRoles = (roles) => ({
  type: types.LIST_AUTH_USER_ROLES,
  payload: roles
})

const listAuthUserPermissions = (permissions) => ({
  type: types.LIST_AUTH_USER_PERMISSIONS,
  payload: permissions
})

const userScopeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_AUTH_USER_ROLES: {
      const actions = getUserActions(action.payload, state.permissions)
      return {
        ...state,
        roles: action.payload,
        actions
      }
    }
    case types.LIST_AUTH_USER_PERMISSIONS: {
      const actions = getUserActions(state.roles, action.payload)
      return {
        ...state,
        permissions: action.payload,
        actions
      }
    }
    default:
      return state
  }
}

export {
  listAuthUserRoles,
  listAuthUserPermissions,
  userScopeReducer
}
