import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { FAILED, SUCCESS, useApi } from './useApi'
import { listAuthUserRoles, listAuthUserPermissions } from '../redux/ducks/userScope'
import { setApiErrorMsg } from '../redux/ducks/notification'

const useListAuthUserRoles = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [requestRole, responseData] = useApi()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const roles = data.data
      dispatch(listAuthUserRoles(roles))
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      }
      dispatch(setApiErrorMsg(`Unable to fetch auth user roles: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestRole, responseData]
}

const useListAuthUserPermissions = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [requestPermission, responseData] = useApi()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const permissions = data.data
      dispatch(listAuthUserPermissions(permissions))
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      }
      dispatch(setApiErrorMsg(`Unable to fetch auth user permissions: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestPermission, responseData]
}

export {
  useListAuthUserRoles,
  useListAuthUserPermissions
}
