import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import _ from 'lodash'

import { FAILED, SUCCESS, useApi } from './useApi'
import { listPlans, loadPlan, createPlanId } from '../redux/ducks/plan'
import { setApiErrorMsg } from '../redux/ducks/notification'

const determineAdFree = (val) => {
  if (_.isEmpty(val) || val === null) return 'false'
  return val?.adFree ? 'true' : 'false'
}

const planAttributes = (plan) => {
  const formattedPlan = {
    name: plan.attributes.name,
    id: plan.id,
    interval: plan.attributes.interval,
    currency: plan.attributes.currency,
    prices: [],
    adFree: determineAdFree(plan.attributes.metadata),
    createdAt: plan.attributes.createdAt,
    updatedAt: plan.attributes.updatedAt
  }

  plan.attributes.prices.forEach((price, idx) => {
    formattedPlan.prices.push({ number: idx + 1, type: price.type, amount: price.amount })
  })

  return formattedPlan
}

const useListPlans = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [requestPlans, responseData] = useApi()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (data?.data.length) {
        const plans = data
        dispatch(listPlans(plans))
      } else {
        dispatch(listPlans({ data: [], meta: { total: 0 } }))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      }
      dispatch(setApiErrorMsg(`Unable to fetch plans: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestPlans, responseData]
}

const useLoadPlan = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [requestPlan, responseData] = useApi()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (_.isEmpty(data.data)) {
        dispatch(setApiErrorMsg('This plan is not found'))
      } else {
        const plan = _.isArray(data.data) ? data.data[0] : data.data
        dispatch(loadPlan(planAttributes(plan)))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      }
      dispatch(setApiErrorMsg(`Unable to load plan: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestPlan, responseData]
}

const useCreatePlan = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [requestPlan, responseData] = useApi()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const planId = data.data.id
      dispatch(createPlanId(planId))
    } else if (state === FAILED) {
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to create plan: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to create plan: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch, history])

  return [requestPlan, responseData]
}

export {
  useListPlans,
  useLoadPlan,
  useCreatePlan
}
