import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Alert, Button, Row, Col
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'

import Loading from '../../../components/Shared/Loading'
import AlertModal from '../../../components/Shared/AlertModal'
import UserMenu from '../../../components/Menu/UserMenu'
import EditUser from '../../../components/User/EditProfile'
import AddEnterpriseUser from '../../../components/User/AddEnterprise'

import { useAuth0 } from '../../../auth/auth0Provider'
import { useLoadUser, useUpdateUser } from '../../../hooks/useUser'
import { useListEnterprises, useLoadEnterprise } from '../../../hooks/useEnterprise'
import { resetUser } from '../../../redux/ducks/user'
import {
  resetAllMessages,
  setIsModal,
  setModalConfirmMsg
} from '../../../redux/ducks/notification'
import {
  getUser,
  getEnterprise,
  getAllEnterprisesByParams,
  updateUserEnterprise
} from '../../../utils/api/skadi'
import { wait } from '../../../utils/helpers'
import { displayDateTime, displayDate } from '../../../utils/format'

const Profile = () => {
  const { user: auth0User } = useAuth0()
  const { id } = useParams()
  const { actions } = useSelector((state) => state.userScope)
  const dispatch = useDispatch()

  const [requestLoadUser] = useLoadUser()
  const [requestLoadEnterprise] = useLoadEnterprise()
  const [requestUpdateUser, userUpdateResponse] = useUpdateUser()
  const [requestListEnterprises, enterprisesResponse] = useListEnterprises()

  const {
    user: { profile },
    enterprise: { enterprise, enterprises: { records } },
    notification: {
      apiMessage: { apiSuccess, apiError },
      modalMessage: { modalDetail },
      isModal
    }
  } = useSelector((state) => state)

  const [isEditUser, setIsEditUser] = useState(false)
  const [showAddEnterpriseToUser, setShowAddEnterpriseToUser] = useState(false)

  useEffect(() => {
    dispatch([resetAllMessages(), resetUser()])
  }, [dispatch])

  useEffect(() => {
    requestLoadUser(getUser(auth0User, id))
  }, [auth0User, id, requestLoadUser])

  useEffect(() => {
    if (profile && profile.enterpriseId) {
      requestLoadEnterprise(getEnterprise(auth0User, profile.enterpriseId))
      setShowAddEnterpriseToUser(false)
    }
  }, [auth0User, profile, requestLoadEnterprise])

  useEffect(() => {
    if (isModal && !modalDetail) {
      dispatch(setModalConfirmMsg(`Are you sure you want to remove enterprise from user ${profile.email}?\n`))
    }
  }, [dispatch, profile, isModal, modalDetail])

  useEffect(() => {
    (async () => {
      if (apiSuccess) {
        if (isEditUser) {
          setIsEditUser(false)
        }
        await wait(2000)
        dispatch(resetAllMessages())
      }
    })()
  }, [apiSuccess, dispatch, profile, isEditUser])

  const handleEditUser = () => {
    setIsEditUser(true)
  }

  const handleAddEnterpriseToUser = () => {
    if (!records.length) {
      const options = {
        params: { limit: 10000 }
      }
      requestListEnterprises(getAllEnterprisesByParams(auth0User, options))
    }
    setShowAddEnterpriseToUser(true)
  }

  const handleRemoveEnterpriseFromUser = () => {
    dispatch(setIsModal(true))
  }

  const submitDelete = () => {
    dispatch([setIsModal(false), setModalConfirmMsg('')])

    const updatePayload = {
      enterpriseId: null
    }
    requestUpdateUser(updateUserEnterprise(auth0User, profile.id, updatePayload))
  }

  if (!profile) {
    return <Loading />
  }

  return (
    <Row className="detail-container">

      {isModal && <AlertModal submit={submitDelete} />}

      <UserMenu />

      {(userUpdateResponse.state === 'loading' || enterprisesResponse.state === 'loading') && <Loading />}

      <Col lg={8} className="detail-generation">

        {apiSuccess && <Alert color="success">{apiSuccess}</Alert>}
        {!modalDetail && apiError && <Alert color="danger">{apiError}</Alert>}

        {isEditUser
          ? <EditUser setIsEditUser={setIsEditUser} />
          : (
            <>
              <div className="detail-el">
                <h3>First Name</h3>
                <p>{profile.firstname || '(empty)'}</p>
              </div>

              <div className="detail-el">
                <h3>Last Name</h3>
                <p>{profile.lastname || '(empty)'}</p>
              </div>

              <div className="detail-el">
                <h3>Email</h3>
                <p>{profile.email || '(empty)'}</p>
              </div>

              <div className="detail-el">
                <h3>Email Verified At</h3>
                <p>{profile.emailVerifiedAt ? displayDate(new Date(profile.emailVerifiedAt)) : '(empty)'}</p>
              </div>

              <div className="detail-el">
                <h3>Type</h3>
                <p>{profile.type || '(empty)'}</p>
              </div>

              <div className="detail-el">
                <h3>Insider ID</h3>
                <p>{profile.id || '(empty)'}</p>
              </div>

              <div className="detail-el">
                <h3>Origin</h3>
                <p>{profile.origin || '(empty)'}</p>
              </div>

              <div className="detail-el enterprise-el">
                <h3>Enterprise</h3>

                <div className="detail-align-center">
                  {profile.enterpriseId && actions.has('loadEnterprise') && (
                  <>
                    <p className="detail-no-margin">{enterprise.name}</p>
                    <input
                      type="button"
                      className="link-inline"
                      value="Remove from Enterprise?"
                      onClick={handleRemoveEnterpriseFromUser}
                    />
                  </>
                  )}

                  {profile.enterpriseId && !actions.has('loadEnterprise') && (
                    <p style={{ color: '#88251E' }}>(You do not have permission to view enterprise)</p>
                  )}

                  {!profile.enterpriseId && (showAddEnterpriseToUser ? (
                    <AddEnterpriseUser setShowAddEnterpriseToUser={setShowAddEnterpriseToUser} />
                  ) : (
                    <>
                      <p className="detail-no-margin">No</p>
                      {actions.has('updateUserEnterprise')
                      && (
                        <input
                          type="button"
                          className="link-inline"
                          value="Add to Enterprise?"
                          onClick={handleAddEnterpriseToUser}
                        />
                      )}
                    </>
                  ))}
                </div>
              </div>

              <div className="detail-el">
                <h3>Updated At</h3>
                <p>{profile.updatedAt ? displayDateTime(new Date(profile.updatedAt)) : '(empty)'}</p>
              </div>

              <div className="detail-el">
                <h3>Created At</h3>
                <p>{profile.createdAt ? displayDateTime(new Date(profile.createdAt)) : '(empty)'}</p>
              </div>

              {actions.has('updateUser')
                && (
                  <div className="btn-container">
                    <Button
                      color="primary"
                      size=""
                      type="button"
                      className="edit-btn"
                      onClick={handleEditUser}
                    > Edit
                    </Button>
                  </div>
                )}
            </>
          )}

      </Col>
    </Row>
  )
}

export default Profile
