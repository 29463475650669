import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PrivateRoute from './auth/PrivateRoute'
import { useAuth0 } from './auth/auth0Provider'
import initFontAwesome from './utils/initFontAwesome'

import Loading from './components/Shared/Loading'
import NavBar from './components/Menu/NavBar'

import HealthCheck from './views/Pages/HealthCheck'
import ServerError from './views/Pages/ServerError'
import NotFound from './views/Pages/NotFound'

import HomePage from './views/HomePage'
import NewUserForm from './views/Users/CreateNewUser'
import SearchUsers from './views/Users/AdvancedSearch'
import Profile from './views/Users/Details/Profile'
import Subscriptions from './views/Users/Details/Subscriptions'
import RoleAssignment from './views/Users/Details/RoleAssignment'
import AccountSettings from './views/Users/Details/AccountSettings'

import EnterprisesDashboard from './views/Enterprises/Dashboard'
import AddNewEnterprise from './views/Enterprises/CreateNewEnterprise'
import EnterpriseDetails from './views/Enterprises/Details/EnterpriseProfile'
import EnterpriseAccess from './views/Enterprises/Details/Access'

import PlansDashboard from './views/Plans/Dashboard'
import CreateNewPlan from './views/Plans/CreateNewPlan'

import Auth0Account from './views/Auth0Account'

initFontAwesome()

const App = () => {
  const { loading } = useAuth0()

  if (loading) {
    return <Loading />
  }

  return (
    <Router>
      <div id="app">
        <NavBar />
        <div className="main-content">
          <Switch>
            <PrivateRoute exact path="/" component={HomePage} scope={[]} />
            <PrivateRoute exact path="/enterprises" component={EnterprisesDashboard} scope={[]} />
            <PrivateRoute exact path="/enterprises/addnew" component={AddNewEnterprise} scope={['createEnterprise']} />
            <PrivateRoute exact path="/enterprises/:id" component={EnterpriseDetails} scope={[]} />
            <PrivateRoute exact path="/enterprises/:id/access-page" component={EnterpriseAccess} scope={[]} />
            <PrivateRoute exact path="/account" component={Auth0Account} scope={[]} />
            <PrivateRoute exact path="/users/addnew" component={NewUserForm} scope={['createUser']} />
            <PrivateRoute exact path="/users/:id/profile" component={Profile} scope={[]} />
            <PrivateRoute exact path="/users/:id/subscriptions" component={Subscriptions} scope={['getSubscription']} />
            <PrivateRoute exact path="/users/:id/roles" component={RoleAssignment} scope={['assignRoles']} />
            <PrivateRoute exact path="/users/:id/settings" component={AccountSettings} scope={['resetPassword', 'deleteUser']} />
            <PrivateRoute exact path="/s/users" component={SearchUsers} scope={[]} />
            <PrivateRoute exact path="/plans" component={PlansDashboard} scope={[]} />
            <PrivateRoute exact path="/plans/addnew" component={CreateNewPlan} scope={['createPlan']} />
            <PrivateRoute path="/500" component={ServerError} scope={[]} />
            <Route exact path="/private/health" component={HealthCheck} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </div>
    </Router>
  )
}

export default App
