import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { FAILED, SUCCESS, useApi } from './useApi'
import {
  listUsers,
  addUser,
  loadUser,
  updateUser,
  resetPasswordUser,
  deleteUser,
  loadUserSubscription,
  listUserRoles,
  listUserPermissions,
  assignUserRole,
  removeUserRole
} from '../redux/ducks/user'
import { setApiErrorMsg, setApiSuccessMsg } from '../redux/ducks/notification'

const userAttributes = (user) => ({
  id: user.id,
  firstname: user.attributes.firstname,
  lastname: user.attributes.lastname,
  email: user.attributes.email,
  emailVerifiedAt: user.attributes.emailVerifiedAt,
  type: user.attributes.type,
  origin: user.attributes.origin,
  enterpriseId: user.attributes.enterpriseId,
  createdAt: user.attributes.createdAt,
  updatedAt: user.attributes.updatedAt,
  roles: user.attributes.roles
})

const userSubscriptionAttributes = (access) => ({
  id: access.attributes.subscription?.id || access.id,
  type: access.attributes.subscription ? 'subscription' : 'access',
  provider: access.attributes.provider,
  // eslint-disable-next-line no-nested-ternary
  providerId: access.attributes.provider === 'insider' ? access.attributes.subscription?.stripeSubscriptionId : ( access.attributes.provider === 'braintree' ? access.attributes.subscription?.braintreeSubscriptionId : 'Other' ),
  plan: access.attributes.subscription?.plan.name,
  status: access.attributes.subscription?.status || (access.attributes.isActive ? 'active' : 'inactive'),
  // eslint-disable-next-line no-nested-ternary
  autoRenew: access.attributes.subscription ? (access.attributes.subscription.autoRenew ? 'yes' : 'no') : null,
  origin: access.attributes.subscription?.origin,
  startsAt: access.attributes.subscription?.startsAt || access.attributes.startsAt,
  endsAt: access.attributes.subscription?.endsAt || access.attributes.endsAt,
  createdAt: access.attributes.subscription?.createdAt || access.attributes.createdAt,
  updatedAt: access.attributes.subscription?.updatedAt || access.attributes.updatedAt
})

const useListUsers = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [requestUser, responseData] = useApi()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (_.isEmpty(data.data)) {
        dispatch(listUsers([]))
      } else {
        const users = data.data
        dispatch(listUsers(users))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      }
      dispatch(setApiErrorMsg(`Unable to fetch users: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestUser, responseData]
}

const useLoadUser = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (_.isEmpty(data.data)) {
        dispatch(setApiErrorMsg('This user is not found'))
      } else {
        const user = _.isArray(data.data) ? data.data[0] : data.data
        dispatch(loadUser(userAttributes(user)))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      } else if (status === 404) {
        history.push('/usernotfound')
      }
      dispatch(setApiErrorMsg(`Unable to load user: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestUser, responseData]
}

const useLoadUserSubscription = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (_.isEmpty(data.data)) {
        dispatch(loadUserSubscription({}))
      } else {
        const access = data.data[0]
        dispatch(loadUserSubscription(userSubscriptionAttributes(access)))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      } else if (status === 404) {
        history.push('/subscriptionnotfound')
      }
      dispatch(setApiErrorMsg(`Unable to load subscription: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestUser, responseData]
}

const useCancelSubscription = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, error, status
    } = responseData
    if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to cancel user subscription: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to cancel user subscription: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch, history])

  return [requestUser, responseData]
}

const useAddUser = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const user = data.data
      dispatch([
        addUser(userAttributes(user)),
        setApiErrorMsg(''),
        setApiSuccessMsg('ok')
      ])
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to create user: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to create user: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch])

  return [requestUser, responseData]
}

const useUpdateUser = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const user = data.data
      dispatch([
        updateUser(userAttributes(user)),
        setApiErrorMsg(''),
        setApiSuccessMsg(`User ${user.attributes.email} is updated!`)])
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to update user: ${error}. Please try again later`))
      } else if (error.includes('at least 1 key')) {
        dispatch(setApiErrorMsg(`Unable to update user: There is no change for this user ${status}`))
      } else {
        dispatch(setApiErrorMsg(`Unable to update user: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch])

  return [requestUser, responseData]
}

const useResetPasswordUser = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const user = data.data
      dispatch([
        resetPasswordUser(userAttributes(user)),
        setApiErrorMsg(''),
        setApiSuccessMsg(`User ${user.attributes.email} password is reset successfully!`)
      ])
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to reset user password: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to reset user password: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch])

  return [requestUser, responseData]
}

const useDeleteUser = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()

  useEffect(() => {
    const { state, error, status } = responseData
    if (state === SUCCESS) {
      dispatch([
        deleteUser(),
        setApiErrorMsg(''),
        setApiSuccessMsg('ok')])
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to delete user: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to delete user: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch])

  return [requestUser, responseData]
}

const useListUserPermissions = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      state, error, status, data
    } = responseData
    if (state === SUCCESS) {
      const permissions = data.data
      dispatch(listUserPermissions(permissions))
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to list user permissions: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to list user permissions: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch])

  return [requestUser, responseData]
}

const useListUserRoles = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const roles = data.data
      dispatch(listUserRoles(roles))
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to list user roles: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to list user roles: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch, history])

  return [requestUser, responseData]
}

const useAssignUserRole = () => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (data.data) {
        const role = data.data
        dispatch([
          assignUserRole(role),
          setApiErrorMsg(''),
          setApiSuccessMsg('User successfully assigned to role')])
      } else {
        dispatch(assignUserRole({}))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      } else if (status === 404) {
        history.push('/rolesnotfound')
      }
      dispatch(setApiErrorMsg(`Unable to assign user to role: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestUser, responseData]
}

const useRemoveUserRole = (value) => {
  const [requestUser, responseData] = useApi()
  const dispatch = useDispatch()

  useEffect(() => {
    const { state, error, status } = responseData
    if (state === SUCCESS) {
      dispatch([
        removeUserRole(value),
        setApiErrorMsg(''),
        setApiSuccessMsg('User successfully removed from role')])
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to remove role: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to remove role: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch, value])

  return [requestUser, responseData]
}

export {
  useListUsers,
  useAddUser,
  useLoadUser,
  useUpdateUser,
  useResetPasswordUser,
  useDeleteUser,
  useLoadUserSubscription,
  useCancelSubscription,
  useListUserRoles,
  useListUserPermissions,
  useAssignUserRole,
  useRemoveUserRole
}
