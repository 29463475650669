import { useRef, useEffect } from 'react'

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

// eslint-disable-next-line import/prefer-default-export
export { usePrevious }
