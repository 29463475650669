import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
  Button, Row, Col, Alert, Badge
} from 'reactstrap'

import Loading from '../../../components/Shared/Loading'
import AlertModal from '../../../components/Shared/AlertModal'
import UserMenu from '../../../components/Menu/UserMenu'
import ResetPassword from '../../../components/User/ResetPassword'

import { useAuth0 } from '../../../auth/auth0Provider'
import { useLoadUser, useDeleteUser } from '../../../hooks/useUser'
import { resetAllMessages, setIsModal, setModalConfirmMsg } from '../../../redux/ducks/notification'
import { getUser, deleteUser } from '../../../utils/api/skadi'
import { wait } from '../../../utils/helpers'

const AccountSettings = () => {
  const { user: auth0User } = useAuth0()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { actions } = useSelector((state) => state.userScope)
  const history = useHistory()

  const [requestLoadUser] = useLoadUser()
  const [requestDeleteUser, deleteUserResponse] = useDeleteUser()

  const {
    user: { profile },
    notification: {
      apiMessage: { apiSuccess, apiError },
      modalMessage: { modalDetail, modalConfirm },
      isModal
    }
  } = useSelector((state) => state)

  const [isResetForm, setIsResetForm] = useState(false)

  useEffect(() => {
    dispatch(resetAllMessages())
  }, [dispatch])

  useEffect(() => {
    requestLoadUser(getUser(auth0User, id))
  }, [auth0User, id, requestLoadUser])

  useEffect(() => {
    if (isModal && !modalDetail) {
      dispatch(setModalConfirmMsg(`Are you sure you want to delete user ${profile.email}?\n`))
    }
  }, [dispatch, profile, isModal, modalDetail])

  useEffect(() => {
    (async () => {
      if (apiSuccess) {
        if (isResetForm) {
          setIsResetForm(false)
        }
        await wait(2000)
        dispatch(resetAllMessages())
      }
    })()
  }, [apiSuccess, dispatch, isResetForm, profile])

  const handleResetPassword = () => {
    setIsResetForm(true)
  }

  const handleDeleteUser = () => {
    dispatch(setIsModal(true))
  }

  const submitDelete = () => {
    dispatch([setIsModal(false), setModalConfirmMsg('')])
    requestDeleteUser(deleteUser(auth0User, id))
  }

  useEffect(() => {
    if (deleteUserResponse.state === 'success') {
      history.push('/', { email: profile.email })
    }
  }, [history, profile, deleteUserResponse])

  return (
    <Row className="detail-container">

      {deleteUserResponse.state === 'loading' && <Loading />}

      {isModal && modalConfirm && (
        <AlertModal
          submit={submitDelete}
          extraDetail={<Badge color="warning">This action can not be undone!</Badge>}
        />
      )}

      <UserMenu />

      <Col lg={8} className="detail-generation">

        {apiSuccess && <Alert color="success">{apiSuccess}</Alert>}

        {!modalDetail && apiError && <Alert color="danger">{apiError}</Alert>}

        {isResetForm
          ? <ResetPassword setIsResetForm={setIsResetForm} />
          : (
            <>
              <h4>
                Settings for user {profile.email}
              </h4>
              <div className="btn-container">
                {actions.has('resetPassword')
                  && (
                    <Button
                      color="success"
                      size="lg"
                      type="button"
                      className="reset-password-btn"
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </Button>
                  )}
                {actions.has('deleteUser')
                  && (
                    <Button
                      color="danger"
                      size="lg"
                      type="button"
                      className="danger-btn"
                      onClick={handleDeleteUser}
                    >
                      Delete User
                    </Button>
                  )}
              </div>
            </>
          )}
      </Col>
    </Row>
  )
}

export default AccountSettings
