const getUserActions = (userRoles = [], userPermissions = []) => {
  const scope = new Set()

  userRoles.forEach(({ attributes: { permissions } }) => {
    permissions.forEach(({ action }) => {
      scope.add(action)
    })
  })

  userPermissions.forEach(({ attributes: { action } }) => {
    scope.add(action)
  })

  return scope
}

export default getUserActions
