import React, { useState, useEffect, useMemo } from 'react'
import {
  useTable, useFilters, useSortBy, usePagination
} from 'react-table'
import { useLocation, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AlertModal from '../Shared/AlertModal'
import Loading from '../Shared/Loading'

import { useAuth0 } from '../../auth/auth0Provider'
import { useListEnterprises } from '../../hooks/useEnterprise'
import { listEnterprises } from '../../redux/ducks/enterprise'
import { getAllEnterprisesByParams } from '../../utils/api/skadi'
import { displayDateTime } from '../../utils/format'
import { setIsModal, setModalDetailMsg } from '../../redux/ducks/notification'

const EnterpriseTable = () => {
  const { user: auth0User } = useAuth0()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()

  const { enterprise } = useSelector((state) => state.enterprise)
  const {
    modalMessage: { modalDetail },
    apiMessage: { apiError },
    isModal
  } = useSelector((state) => state.notification)
  const [requestListEnterprises, enterprisesResponse] = useListEnterprises()
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { enterprises: { records, enterpriseTotal } } = useSelector((state) => state.enterprise)

  const [name, setName] = useState('')
  const [manualSortBy, setManualSortBy] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'attributes.name'
      },
      {
        Header: 'Code',
        id: 'code',
        accessor: 'attributes.code'
      },
      {
        Header: 'Seats',
        id: 'seats',
        accessor: (row) => (row.attributes.seats ? `${row.attributes.seats}` : '(empty)')
      },
      {
        Header: 'Updated At',
        id: 'updatedAt',
        accessor: 'attributes.updatedAt',
        Cell: ({ cell: { value } }) => displayDateTime(new Date(value))
      },
      {
        Header: 'Created At',
        id: 'createdAt',
        accessor: 'attributes.createdAt',
        Cell: ({ cell: { value } }) => displayDateTime(new Date(value))
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { sortBy }
  } = useTable(
    {
      columns,
      data: records,
      manualSortBy,
      initialState: { pageIndex: 0, pageSize: 25 }
    },
    useFilters,
    useSortBy,
    usePagination
  )

  const handleChange = (e) => {
    const value = e.target.value || ''
    setName(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const params = {}
    if (name) {
      params.name = `%${name}%`
      params.limit = 100
    }
    const options = { sortBy: 'updatedAt', params }
    requestListEnterprises(getAllEnterprisesByParams(auth0User, options))
    return setIsSubmitted(true)
  }

  useEffect(() => {
    dispatch(listEnterprises({ data: [], meta: { total: 0 } }))
  }, [dispatch])

  useEffect(() => {
    if (!isModal) {
      setIsSubmitted(false)
    }
    if (pathname !== '/s/enterprises') {
      setManualSortBy(true)
      let { id, desc } = sortBy[0] || []
      if (!id || id === 'domains') {
        id = 'updatedAt'
        desc = true
      }

      const order = desc ? 'desc' : 'asc'
      const options = { order, sortBy: id }
      requestListEnterprises(getAllEnterprisesByParams(auth0User, options))
    }
    if (isSubmitted) {
      if (apiError) {
        return dispatch([setIsModal(true), setModalDetailMsg(apiError)])
      }
      if (enterprise?.id) {
        return history.push(`/enterprises/${enterprise.id}`)
      }
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0User, pathname, sortBy, enterprise, apiError])

  const onRowClick = (cell) => {
    const { id } = cell.row.original
    history.push(`/enterprises/${id}`)
  }

  return (
    <>
      <AlertModal modalMessage={modalDetail} />
      {enterprisesResponse.state === 'loading' && <Loading />}

      <div className="dashboard-head">
        <p className="page-show">Showing {page.length} of {enterpriseTotal} Total Records</p>

        <div className="enterprise-search-box">
          <form className="enterprise-search-input" onSubmit={handleSubmit}>
            <Input
              value={name}
              onChange={handleChange}
              className="enterprise-search-form"
              placeholder="Search enterprise by name"
            />
          </form>
          <FontAwesomeIcon icon="search" className="magnifying-glass" onClick={handleSubmit} />
        </div>
      </div>

      <table className="table-container enterprise-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''
                  }
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td onClick={() => onRowClick(cell)} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )

}

EnterpriseTable.defaultProps = {
  columns: [],
  data: [],
  cell: { value: '' }
}

EnterpriseTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.objectOf()),
  data: PropTypes.arrayOf(PropTypes.objectOf()),
  cell: PropTypes.shape({ value: PropTypes.string })
}

export default EnterpriseTable
