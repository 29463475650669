import React from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'
import { Alert } from 'reactstrap'
import { resetAllMessages } from '../../../redux/ducks/notification'

import style from './style.module.scss'

import PlanDisplay from '../PlanDisplay'

const PlanPreview = ({ dataToDisplay, onContinueEditing, onCreatePlan }) => {
  const dispatch = useDispatch()
  const { apiMessage: { apiError } } = useSelector((state) => state.notification)

  const handleServerErrorAlertDismiss = () => dispatch(resetAllMessages())

  return (
    <div className={style.wrapper}>

      <h1 className={style.title}>Preview plan</h1>

      <PlanDisplay dataToDisplay={dataToDisplay} />

      <Alert className={style.alert} color="warning">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" /><line x1="12" y1="9" x2="12" y2="13" /><line x1="12" y1="17" x2="12.01" y2="17" /></svg>
        <span className={style.alertText}>Please review: </span>
        Once created, plans can not be edited. Plan must be deleted.
      </Alert>

      <div className={style.buttonWrapper}>
        <button className={style.continueEditingButton} type="button" onClick={onContinueEditing}>Continue editing</button>
        <button className={style.createPlanButton} type="button" onClick={onCreatePlan}>Create plan</button>
      </div>

      <Alert className={style.alert} color="danger" isOpen={!!apiError} toggle={handleServerErrorAlertDismiss}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-slash"><circle cx="12" cy="12" r="10" /><line x1="4.93" y1="4.93" x2="19.07" y2="19.07" /></svg>
        <span className={style.alertText}>Server error:</span> {apiError}
      </Alert>
    </div>
  )

}

PlanPreview.propTypes = {
  dataToDisplay: PropTypes.shape({
    planName: PropTypes.string,
    planProvider: PropTypes.string,
    adFree: PropTypes.string,
    planInterval: PropTypes.string,
    planCurrency: PropTypes.string,
    price1Amount: PropTypes.string,
    price2Amount: PropTypes.string
  }).isRequired,
  onContinueEditing: PropTypes.func.isRequired,
  onCreatePlan: PropTypes.func.isRequired
}

export default PlanPreview
