/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm, useFieldArray } from 'react-hook-form'
import { Button, Tooltip } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

import dayjs from 'dayjs'
import Loading from '../Shared/Loading'
import Input from '../Form/Input'

import { useAuth0 } from '../../auth/auth0Provider'
import { usePrevious } from '../../utils/customHooks'
import { useUpdateEnterprise, useUpdateEnterpriseAccess } from '../../hooks/useEnterprise'
import { resetAllMessages, setApiSuccessMsg, setApiErrorMsg } from '../../redux/ducks/notification'
import { bulkAddEnterpriseUsers, updateEnterprise } from '../../utils/api/skadi'
import { useApi } from '../../hooks/useApi'
import { updateEnterpriseAccess } from '../../utils/api/sigyn'
import { convertToDateTimeLocalString } from '../../utils/format'

const EditEnterprise = ({
  setShowEditEnterprise, isName, isSeats, isDomains, isBulkEmail, isDownloadUsers, isHubSpotId, isEndsAt
}) => {
  const { user: auth0User } = useAuth0()
  const [request] = useApi()
  const { id } = useParams()
  const dispatch = useDispatch()

  const [requestEditEnterprise, enterpriseResponse] = useUpdateEnterprise()
  const [requestEditAccess, accessResponse] = useUpdateEnterpriseAccess()
  const { enterprise, access } = useSelector((state) => state.enterprise)

  const [updatedEnterprise, setEnterprise] = useState(enterprise)
  const [updatedAccess, setAccess] = useState(access)
  const [isHoverAdd, setHoverAdd] = useState(false)

  const {
    handleSubmit, register, control, errors
  } = useForm({
    defaultValues: {
      domains: updatedEnterprise.domains ? updatedEnterprise.domains.map((d) => ({ domain: d })) : [{ domain: '' }]
    }
  })

  const { fields, append, remove } = useFieldArray({ control, name: 'domains' })

  useEffect(() => {
    dispatch(resetAllMessages())
  }, [dispatch])

  const {
    name: enterpriseName, seats, domains, users
  } = enterprise

  const prevUserData = usePrevious({ name: enterpriseName, seats, domains })

  const onSubmit = (data) => {
    if (data.endsAt) {
      requestEditAccess(updateEnterpriseAccess(auth0User, access.id, { endsAt: data.endsAt }))
    } else {
      const payload = {}
      if (data.name && data.name !== prevUserData.name) {
        payload.name = data.name
      }

      if (data.seats && parseInt(data.seats, 10) !== prevUserData.seats) {
        payload.seats = parseInt(data.seats, 10)
      }

      if (data.domains) {
        const domainsPayload = data.domains.map((field) => field.domain)
        const isFieldChanged = _.xor(domainsPayload, prevUserData.domains).length !== 0
        if (isFieldChanged) {
          payload.domains = [...new Set(domainsPayload)]
        }
      }

      if (data.emails) {
        request(bulkAddEnterpriseUsers(auth0User, id, { users: data.emails }))
      }

      if (data.hubSpotId) {
        payload.hubSpotId = data.hubSpotId
      }

      if (_.isEmpty(data) || ('seats' in data && !data.seats)) {
        dispatch(setApiErrorMsg('Fields to update can not empty values'))
      } else if (_.isEmpty(payload)) {
        dispatch(setApiSuccessMsg(`No change for enterprise ${enterprise.name}`))
      } else {
        requestEditEnterprise(updateEnterprise(auth0User, id, payload))
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'endsAt') {
      const formattedDate = dayjs(value).format('YYYY-MM-DDTHH:mm:ss')
      setAccess({ ...updatedAccess, endsAt: formattedDate })
    } else {
      setEnterprise({ ...updatedEnterprise, [name]: value })
    }
  }

  const handleCancel = () => {
    setShowEditEnterprise(false)
    dispatch(resetAllMessages())
  }

  const handleDownloadUsers = () => {
    const usersObject = Object.values(users)
    const headers = Object.keys(usersObject[0]).filter((key) => key === 'email')
    const csvContent = [
      headers.join(','),
      ...usersObject.map((obj) => headers.map((header) => obj[header]).join(','))
    ].join('\n')
    const blob = new Blob([csvContent], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const enterpriseNameFormatted = enterprise.name.split(' ').join('_').toLowerCase()
    link.download = `${enterpriseNameFormatted}_list.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    console.log('Downloaded Enterprise User List')
  }

  return (
    <>
      {enterpriseResponse.state === 'loading' && <Loading />}
      {accessResponse.state === 'loading' && <Loading />}

      {isName && (
        <form className="form-edit-enterprise" onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="form-input"
            name="name"
            value={updatedEnterprise.name || ''}
            onChange={handleChange}
            hideLabel
            ref={register}
          />

          <Button
            color="success"
            size="sm"
            type="submit"
            className="small-btn small-margin-btn"
          >
            Save
          </Button>

          <Button
            color="secondary"
            size="sm"
            type="button"
            className="small-btn"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </form>
      )}

      {isSeats && (
        <form className="form-edit-enterprise" onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="form-input"
            name="seats"
            value={updatedEnterprise.seats || ''}
            onChange={handleChange}
            type="number"
            hideLabel
            ref={register}
          />
          <Button
            color="success"
            size="sm"
            type="submit"
            className="small-btn small-margin-btn"
          >
            Save
          </Button>

          <Button
            color="secondary"
            size="sm"
            type="button"
            className="small-btn"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </form>
      )}

      {isDomains && (
        <form className="form-edit-enterprise form-edit-array" onSubmit={handleSubmit(onSubmit)}>
          <ul>
            {fields.map((item, index) => (
              <li key={item.id} className="form-array-el">
                <input
                  className="form-input"
                  name={`domains[${index}].domain`}
                  defaultValue={`${item.domain}`}
                  ref={register()}
                />

                <FontAwesomeIcon
                  className="inline-icon"
                  icon="trash-alt"
                  onClick={() => remove(index)}
                />

              </li>
            ))}
          </ul>

          <section>
            <Tooltip placement="right" isOpen={isHoverAdd} target="TooltipAdd" toggle={() => setHoverAdd(!isHoverAdd)}>
              Add more domain
            </Tooltip>
            <FontAwesomeIcon
              id="TooltipAdd"
              className="inline-icon inline-margin-bottom-icon"
              icon="plus"
              onClick={() => append({ domain: '' })}
            />
          </section>

          <div className="edit-horizontal-btn">
            <Button
              color="success"
              size="sm"
              type="submit"
              className="small-btn small-margin-btn"
            >
              Save
            </Button>

            <Button
              color="secondary"
              size="sm"
              type="button"
              className="small-btn"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>

        </form>
      )}

      {isBulkEmail && (
        <form className="form-edit-enterprise form-edit-array" onSubmit={handleSubmit(onSubmit)}>
          <ul>
            {fields.map((item, index) => (
              <li key={item.id} className="form-array-el">
                <input
                  className="form-input"
                  name={`emails[${index}].email`}
                  defaultValue=""
                  ref={register({ required: 'Field must be filled' })}
                />
                <FontAwesomeIcon
                  className="inline-icon"
                  icon="trash-alt"
                  onClick={() => remove(index)}
                />

              </li>
            ))}
          </ul>

          <section>
            <Tooltip placement="right" isOpen={isHoverAdd} target="TooltipAdd" toggle={() => setHoverAdd(!isHoverAdd)}>
              Add more users
            </Tooltip>
            <FontAwesomeIcon
              id="TooltipAdd"
              className="inline-icon inline-margin-bottom-icon"
              icon="plus"
              onClick={() => append({ email: '' })}
            />
          </section>

          <small className="py-1 text-muted">*Users added to this enterprise will be removed from others</small>

          <div className="edit-horizontal-btn">
            <Button
              color="success"
              size="sm"
              type="submit"
              className="small-btn small-margin-btn"
            >
              Save
            </Button>

            <Button
              color="secondary"
              size="sm"
              type="button"
              className="small-btn"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
          {errors.emails && <span className="error">{errors.emails[0].email.message}</span>}

        </form>
      )}

      { isDownloadUsers && (
        <div>
          <div className="detail-el">
            <h3>Download User List</h3>
            <p>&nbsp;</p>
            <FontAwesomeIcon
              id="TooltipAdd"
              className="inline-icon inline-margin-bottom-icon"
              icon="download"
              onClick={handleDownloadUsers}
            />
          </div>
          <Tooltip placement="right" isOpen={isHoverAdd} target="TooltipAdd" toggle={() => setHoverAdd(!isHoverAdd)}>
            Download enterprise user list
          </Tooltip>
        </div>
      )}

      {isHubSpotId && (
        <form className="form-edit-enterprise" onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="form-input"
            name="hubSpotId"
            value={updatedEnterprise.hubSpotId || ''}
            onChange={handleChange}
            hideLabel
            ref={register}
          />
        </form>
      )}

      {isEndsAt && (
        <form className="form-edit-enterprise" onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="form-input"
            name="endsAt"
            value={convertToDateTimeLocalString(new Date(updatedAccess.endsAt)) || ''}
            onChange={handleChange}
            hideLabel
            type="datetime-local"
            ref={register}
            shrinkInput
          />

          <Button
            color="success"
            size="sm"
            type="submit"
            className="small-btn small-margin-btn"
          >
            Save
          </Button>

          <Button
            color="secondary"
            size="sm"
            type="button"
            className="small-btn"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </form>
      )}
    </>
  )
}

EditEnterprise.defaultProps = {
  isName: false,
  isSeats: false,
  isDomains: false,
  isBulkEmail: false,
  isDownloadUsers: false,
  isHubSpotId: false,
  isEndsAt: false,
  setShowEditEnterprise: () => {}
}

EditEnterprise.propTypes = {
  isName: PropTypes.bool,
  isSeats: PropTypes.bool,
  isDomains: PropTypes.bool,
  isBulkEmail: PropTypes.bool,
  isDownloadUsers: PropTypes.bool,
  isHubSpotId: PropTypes.bool,
  isEndsAt: PropTypes.bool,
  setShowEditEnterprise: PropTypes.func
}

export default EditEnterprise
