import setHeaders from './setHeaders'

const {
  REACT_APP_SKADI_ORIGIN: SKADI_URL,
  REACT_APP_MEMBERSHIP_API_ORIGIN: MEMBERSHIP_API_URL
} = process.env

const getAllUsersSort = (user, options = {}) => ({
  url: `${SKADI_URL}/users`,
  params: {
    sortBy: options.field,
    sortOrder: options.order
  },
  ...setHeaders(user)
})

const searchUser = (user, params) => ({
  url: `${SKADI_URL}/users?`,
  params,
  ...setHeaders(user)
})

const getUser = (user, id) => ({
  url: `${SKADI_URL}/users/${id}`,
  ...setHeaders(user)
})

const createUser = (user, data) => ({
  url: `${MEMBERSHIP_API_URL}/users`,
  method: 'post',
  data,
  ...setHeaders(user)
})

const updateUserEnterprise = (user, id, data) => ({
  url: `${MEMBERSHIP_API_URL}/users/${id}/enterprises`,
  method: 'patch',
  data,
  ...setHeaders(user)
})

const bulkAddEnterpriseUsers = (user, id, data) => ({
  url: `${SKADI_URL}/enterprises/${id}/users/bulk`,
  method: 'patch',
  data,
  ...setHeaders(user, { isInternal: true })
})

const updateUser = (user, id, data) => ({
  url: `${MEMBERSHIP_API_URL}/users/${id}`,
  method: 'patch',
  data,
  ...setHeaders(user)
})

const resetPassword = (user, data) => ({
  url: `${MEMBERSHIP_API_URL}/auth/reset-password`,
  method: 'post',
  data,
  ...setHeaders(user)
})

const deleteUser = (user, id) => ({
  url: `${MEMBERSHIP_API_URL}/users/${id}`,
  method: 'delete',
  ...setHeaders(user)
})

const createEnterprise = (user, data) => ({
  url: `${MEMBERSHIP_API_URL}/enterprises`,
  method: 'post',
  data,
  ...setHeaders(user)
})

const getAllEnterprisesByParams = (user, options = {}) => {
  const { sortBy, order } = options

  const validFields = ['name', 'seats', 'connection', 'code', 'createdAt', 'updatedAt']
  const validOrders = ['asc', 'desc']

  const params = { ...options.params }

  if (!sortBy || !validFields.includes(sortBy)) {
    params.sortBy = 'name'
  } else {
    params.sortBy = sortBy
  }

  if (!order || !validOrders.includes(order)) {
    const ascendingFields = ['name', 'code', 'connection']
    params.sortOrder = ascendingFields.includes(params.sortBy) ? 'asc' : 'desc'
  } else {
    params.sortOrder = order
  }

  return {
    url: `${MEMBERSHIP_API_URL}/enterprises`,
    params,
    ...setHeaders(user)
  }
}

const getEnterprise = (user, id) => ({
  url: `${MEMBERSHIP_API_URL}/enterprises/${id}`,
  params: { include: 'users', expand: 'owner' },
  ...setHeaders(user)
})

const updateEnterprise = (user, id, data) => ({
  url: `${MEMBERSHIP_API_URL}/enterprises/${id}`,
  method: 'patch',
  data,
  ...setHeaders(user)
})

const getUserRoles = (user, id) => ({
  url: `${SKADI_URL}/users/${id}/user-roles?include=permissions`,
  ...setHeaders(user)
})

const getUserPermissions = (user, id) => ({
  url: `${SKADI_URL}/users/${id}/user-permissions`,
  ...setHeaders(user)
})

const getAllRolesSort = (user, options = {}) => {
  const LIMIT = 2000
  const { field, order, limit } = options

  const validFields = ['name', 'createdAt', 'updatedAt']
  const validOrders = ['asc', 'desc']

  const params = {}

  if (!field || !validFields.includes(field)) {
    params.sortBy = 'name'
  } else {
    params.sortBy = field
  }

  if (!order || !validOrders.includes(order)) {
    const ascendingFields = ['name']
    params.sortOrder = ascendingFields.includes(params.field) ? 'asc' : 'desc'
  } else {
    params.sortOrder = order
  }

  if (!limit || limit < 1) {
    params.limit = LIMIT
  }

  return {
    url: `${SKADI_URL}/resources/e27c8c0e-5b17-4032-b7f8-f376b572018f/roles`,
    params,
    ...setHeaders(user)
  }
}

const assignUserRole = (user, id, data) => ({
  url: `${SKADI_URL}/users/${id}/user-roles`,
  method: 'post',
  data,
  ...setHeaders(user)
})

const removeUserRole = (user, id) => ({
  url: `${SKADI_URL}/user-roles/${id}`,
  method: 'delete',
  ...setHeaders(user)
})

export {
  setHeaders,
  getAllUsersSort,
  getAllEnterprisesByParams,
  getAllRolesSort,
  assignUserRole,
  removeUserRole,
  getUser,
  searchUser,
  createUser,
  createEnterprise,
  resetPassword,
  updateUserEnterprise,
  bulkAddEnterpriseUsers,
  updateUser,
  deleteUser,
  getEnterprise,
  updateEnterprise,
  getUserRoles,
  getUserPermissions
}
