import React from 'react'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import EnterpriseTable from '../../components/Tables/EnterpriseTable'

const Dashboard = () => {
  const { actions } = useSelector((state) => state.userScope)
  const history = useHistory()

  return (
    <div className="dashboard">
      {actions.has('createEnterprise')
      && (
        <Button
          className="dashboard-add-new-btn"
          type="button"
          onClick={() => history.push('/enterprises/addnew')}
        >
          Add Enterprise
        </Button>
      )}
      <EnterpriseTable />
    </div>
  )
}

export default Dashboard
