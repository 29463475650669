import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { Alert, Badge } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resetAllMessages } from '../../../redux/ducks/notification'
import { resetPlanId } from '../../../redux/ducks/plan'

import style from './style.module.scss'

import CopyPlanIdButton from '../CopyPlanIdButton'
import PlanDisplay from '../PlanDisplay'

const PlanSuccess = ({ dataToDisplay }) => {
  const { planId } = useSelector((state) => state.plan)
  const dispatch = useDispatch()

  const handleViewAllPlans = () => {
    dispatch([resetPlanId(), resetAllMessages()])
  }

  return (
    <div className={style.wrapper}>

      <Badge className={style.badge} color="success">Success!</Badge>

      <div className={style.successMessageWrapper}>
        <h1>{dataToDisplay.planName} created!</h1>
        <CopyPlanIdButton planId={planId} />
      </div>

      <p className={style.overviewText}>Plan Overview:</p>

      <PlanDisplay dataToDisplay={dataToDisplay} />

      <Alert className={style.alert} color="warning">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-octagon"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" /><line x1="12" y1="8" x2="12" y2="12" /><line x1="12" y1="16" x2="12.01" y2="16" /></svg>
        <span className={style.alertText}>Caution: </span>
        You may only delete a plan if no one has subscribed to it.
      </Alert>

      <div className={style.buttonWrapper}>
        <button className={style.deletePlanButton} type="button" onClick={() => console.log('delete plan')}>Delete plan</button>
        <Link className={style.viewAllPlansLink} onClick={handleViewAllPlans} to="/plans">View all plans</Link>
      </div>
    </div>
  )
}

PlanSuccess.propTypes = {
  dataToDisplay: PropTypes.shape({
    planName: PropTypes.string,
    planProvider: PropTypes.string,
    adFree: PropTypes.string,
    planInterval: PropTypes.string,
    validFor: PropTypes.string,
    planCurrency: PropTypes.string,
    price1Amount: PropTypes.string,
    price2Amount: PropTypes.string
  }).isRequired
}

export default PlanSuccess
