/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Alert } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../../components/Shared/Loading'
import EnterpriseMenu from '../../../components/Menu/EnterpriseMenu'
import EditEnterprise from '../../../components/Enterprise/EditEnterprise'

import { useAuth0 } from '../../../auth/auth0Provider'
import { useLoadEnterprise, useLoadEnterpriseAccess, useLoadEnterpriseSubscription } from '../../../hooks/useEnterprise'
import { resetEnterprise } from '../../../redux/ducks/enterprise'
import { resetAllMessages } from '../../../redux/ducks/notification'
import { getEnterprise } from '../../../utils/api/skadi'
import { getEnterpriseAccesses, getEnterpriseSubscription } from '../../../utils/api/sigyn'
import { displayDateTime, capitalizeFirstLetter } from '../../../utils/format'
import { wait } from '../../../utils/helpers'

const EnterpriseAccess = () => {
  const { user: auth0User } = useAuth0()
  const { id } = useParams()
  const dispatch = useDispatch()

  const [requestLoadEnterpriseAccess] = useLoadEnterpriseAccess()
  const [requestLoadEnterprise] = useLoadEnterprise()
  const [requestLoadEnterpriseSubscription] = useLoadEnterpriseSubscription()
  const [showEditEndsAt, setShowEditEndsAt] = useState(false)

  const {
    enterprise: { enterprise, access, subscription },
    userScope: { actions },
    notification: {
      apiMessage: { apiSuccess, apiError },
      modalMessage: { modalDetail }
    }
  } = useSelector((state) => state)

  useEffect(() => {
    dispatch([resetEnterprise(), resetAllMessages()])
  }, [dispatch])

  useEffect(() => {
    requestLoadEnterprise(getEnterprise(auth0User, id))
  }, [auth0User, id, requestLoadEnterprise])

  useEffect(() => {
    if (enterprise?.ownerId && enterprise?.type === 'Corp') {
      requestLoadEnterpriseSubscription(getEnterpriseSubscription(auth0User, id))
    }
  }, [auth0User, enterprise?.ownerId, enterprise?.type, id, requestLoadEnterpriseSubscription])

  useEffect(() => {
    if (enterprise?.type !== 'Corp') {
      requestLoadEnterpriseAccess(getEnterpriseAccesses(auth0User, id))
    }
  }, [auth0User, enterprise?.type, id, requestLoadEnterpriseAccess])

  useEffect(() => {
    (async () => {
      if (apiSuccess) {
        if (showEditEndsAt) {
          setShowEditEndsAt(false)
        }
        await wait(2000)
        dispatch(resetAllMessages())
      }
    })()
  }, [apiSuccess, dispatch, enterprise, actions, showEditEndsAt])

  if (!modalDetail && apiError) {
    return <Alert color="danger">{apiError}</Alert>
  }

  if (!enterprise) {
    return <Loading />
  }

  const handleEditEndsAt = () => {
    setShowEditEndsAt(true)
  }

  return (
    <Row className="detail-container">
      <EnterpriseMenu id={id} showAccess />
      {!subscription && <Loading />}
      {!access && <Loading />}
      <Col lg={7} className="detail-generation">
        {(!subscription.id && !access.id) && null }
        {(enterprise.type === 'Corp' && enterprise.ownerId) && (
        <>
          <div className="detail-el">
            <h3>Account Owner</h3>
            <p>
              {enterprise.ownerId}
            </p>
          </div>

          <div className="detail-el">
            <h3>Status</h3>
            <p>
              {subscription.status
                ? capitalizeFirstLetter(subscription.status)
                : '(empty)'}
            </p>
          </div>

          <div className="detail-el">
            <h3>Auto Renew</h3>
            <p>
              {subscription.autoRenew ? 'True' : 'False'}
            </p>
          </div>

          <div className="detail-el">
            <h3>Origin</h3>
            <p>{subscription.origin || '(empty)'}</p>
          </div>

          <div className="detail-el">
            <h3>Starts At</h3>
            <p>
              {subscription.startsAt
                ? displayDateTime(new Date(subscription.startsAt))
                : '(empty)'}
            </p>
          </div>

          <div className="detail-el">
            <h3>Ends At</h3>
            <p>
              {subscription.endsAt
                ? displayDateTime(new Date(subscription.endsAt))
                : 'No Expiration Date'}
            </p>
          </div>

          <div className="detail-el">
            <h3>Updated At</h3>
            <p>
              {subscription.updatedAt
                ? displayDateTime(new Date(subscription.updatedAt))
                : '(empty)'}
            </p>
          </div>

          <div className="detail-el">
            <h3>Created At</h3>
            <p>
              {subscription.createdAt
                ? displayDateTime(new Date(subscription.createdAt))
                : '(empty)'}
            </p>
          </div>
        </>
        )}

        {enterprise.type !== 'Corp' && (
        <>
          <div className="detail-el">
            <h3>Provider</h3>
            <p>
              {access.provider ? capitalizeFirstLetter(access.provider) : '(empty)'}
            </p>
          </div>

          <div className="detail-el">
            <h3>Plan</h3>
            <p>Contract</p>
          </div>

          <div className="detail-el">
            <h3>Auto Renew</h3>
            <p>
              Contract
            </p>
          </div>

          <div className="detail-el">
            <h3>Origin</h3>
            <p>
              Contract
            </p>
          </div>

          <div className="detail-el">
            <h3>Starts At</h3>
            <p>
              {access.startsAt
                ? displayDateTime(new Date(access.startsAt))
                : '(empty)'}
            </p>
          </div>

          <div className="detail-el">
            <h3>Ends At</h3>
            {showEditEndsAt ? <EditEnterprise setShowEditEnterprise={setShowEditEndsAt} isEndsAt />
              : (
                <>
                  <p>
                    {access?.endsAt
                      ? displayDateTime(new Date(access.endsAt))
                      : '(empty)'}
                  </p>
                  {actions.has('updateUserEnterprise') && ( <FontAwesomeIcon className="inline-icon" icon="edit" onClick={handleEditEndsAt} />)}
                </>
              )}
          </div>

          <div className="detail-el">
            <h3>Updated At</h3>
            <p>
              {access?.updatedAt
                ? displayDateTime(new Date(access.updatedAt))
                : '(empty)'}
            </p>
          </div>

          <div className="detail-el">
            <h3>Created At</h3>
            <p>
              {access?.createdAt
                ? displayDateTime(new Date(access.createdAt))
                : '(empty)'}
            </p>
          </div>
        </>
        )}

      </Col>
    </Row>
  )
}
export default EnterpriseAccess
