import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import style from './style.module.scss'

import PlanTable from '../../../components/Tables/PlanTable/index'

const Dashboard = () => {
  const { actions } = useSelector((state) => state.userScope)
  return (
    <div className="dashboard">

      {actions.has('createPlan')
    && <Link to="/plans/addnew" className={style.linkButton}>Create a plan</Link>}

      <PlanTable />

    </div>
  )
}

export default Dashboard
