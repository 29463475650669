import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@mui/material/Tooltip'

import style from './style.module.scss'

const CopyButton = ({ handleClick, isSelected, ...props }) => (
  <Tooltip
    title={isSelected ? 'Copied!' : 'Copy plan ID'}
    arrow
    {...props}
  >
    <button
      type="button"
      className={style.button}
      onClick={handleClick}
    >
      {isSelected ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#007BFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-check"
          title="Copied!"
        >
          <polyline points="20 6 9 17 4 12" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#6C757D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-link"
          title="Copy plan ID"
        >
          <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
          <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
        </svg>
      )}
    </button>
  </Tooltip>
)

CopyButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default CopyButton
