import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'

import UserTable from '../../components/Tables/UserTable'
import AlertModal from '../../components/Shared/AlertModal'
import Loading from '../../components/Shared/Loading'

import { useAuth0 } from '../../auth/auth0Provider'
import { useListUsers } from '../../hooks/useUser'
import { listUsers } from '../../redux/ducks/user'
import { resetAllMessages, setIsModal, setModalDetailMsg } from '../../redux/ducks/notification'
import { searchUser } from '../../utils/api/skadi'
import { wait } from '../../utils/helpers'

const AdvancedSearch = () => {
  const { user: auth0User } = useAuth0()
  const dispatch = useDispatch()

  const [requestListUsers] = useListUsers()

  useEffect(() => {
    dispatch(resetAllMessages())
  }, [dispatch])

  const {
    user: { users },
    notification: {
      apiMessage: { apiError },
      isModal
    }
  } = useSelector((state) => state)

  const [input, setInput] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isModal) {
      setIsSubmitted(false)
    }
    if (isSubmitted) {
      if (apiError) {
        return dispatch([setIsModal(true), setModalDetailMsg(apiError)])
      }

      if (!users.length) {
        return dispatch([setIsModal(true), setModalDetailMsg('User is not found')])
      }
    }
    return null
  }, [dispatch, users, apiError, isModal, isSubmitted])

  const handleChange = (e) => {
    const { name, value } = e.target || {}
    setInput({
      ...input,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { firstname, lastname, email } = input
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!firstname && !lastname && !email) {
      return dispatch(listUsers([]))
    }

    if (email) {
      if (!emailRegex.test(email)) {
        return dispatch([setIsModal(true), setModalDetailMsg('Invalid Email Input')])
      }
    }

    const params = {
      email: email || undefined,
      firstname: firstname || undefined,
      lastname: lastname || undefined
    }

    requestListUsers(searchUser(auth0User, params))
    setIsLoading(true)
    await wait(500)
    setIsLoading(false)
    return setIsSubmitted(true)
  }

  const handleReset = () => {
    setInput({})
    dispatch(listUsers([]))
  }

  return (
    <div className="advanced-search-box">
      {isLoading && <Loading />}

      <form
        className="advanced-search-form-container"
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        <div className="advanced-search-el">
          <label>First Name</label>
          <input name="firstname" onChange={handleChange} placeholder="Search user by first name" />
        </div>

        <div className="advanced-search-el">
          <label>Last Name</label>
          <input name="lastname" onChange={handleChange} placeholder="Search user by last name" />
        </div>

        <div className="advanced-search-el">
          <label>Email</label>
          <input name="email" onChange={handleChange} placeholder="Search user by email" />
        </div>

        <div className="advanced-search-btn-container">
          <Button
            color="primary"
            size="lg"
            type="submit"
            className="advanced-search-submit advanced-search-btn-submit"
          >
            search
          </Button>
          <Button
            color="secondary"
            size="lg"
            type="reset"
            className="advanced-search-submit advanced-search-btn-clear"
          >
            clear
          </Button>
        </div>
      </form>

      <AlertModal modalMessage={apiError} />

      <UserTable />
    </div>
  )
}

export default AdvancedSearch
