const { REACT_APP_NODE_ENV } = process.env

module.exports = (user, headersOptions = {}) => {
  const options = {
    headers: {
      Authorization: `Bearer ${user['https://insider/token']}`
    }
  }

  if (headersOptions.isInternal && REACT_APP_NODE_ENV !== 'production') {
    options.headers['x-uid'] = user['https://insider/memberId']
  }

  if (REACT_APP_NODE_ENV === 'production') {
    options.withCredentials = true
  }

  return options
}
