const types = {
  LIST_USERS: 'LIST_USERS',
  LOAD_USER: 'LOAD_USER',
  ADD_USER: 'ADD_USER',
  UPDATE_USER: 'UPDATE_USER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  DELETE_USER: 'DELETE_USER',
  LOAD_SUBSCRIPTION: 'LOAD_SUBSCRIPTION',
  RESET_USER: 'RESET_USER',
  LIST_USER_ROLES: 'LIST_USER_ROLES',
  LIST_USER_PERMISSIONS: 'LIST_USER_PERMISSIONS',
  ASSIGN_ROLE: 'ASSIGN_ROLE',
  REMOVE_ROLE: 'REMOVE_ROLE'
}

const initialState = {
  users: [],
  profile: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    type: '',
    origin: '',
    enterpriseId: '',
    createdAt: '',
    updatedAt: '',
    emailVerifiedAt: ''
  },
  subscription: {
    id: '',
    type: '',
    provider: '',
    status: '',
    autoRenew: '',
    plan: '',
    startsAt: '',
    endsAt: '',
    origin: '',
    createdAt: '',
    updatedAt: ''
  },
  roles: [],
  permissions: []
}

const listUsers = (users) => ({
  type: types.LIST_USERS,
  payload: users
})

const loadUser = (user) => ({
  type: types.LOAD_USER,
  payload: user
})

const loadUserSubscription = (subscription) => ({
  type: types.LOAD_SUBSCRIPTION,
  payload: subscription
})

const addUser = (user) => ({
  type: types.ADD_USER,
  payload: user
})

const updateUser = (user) => ({
  type: types.UPDATE_USER,
  payload: user
})

const resetPasswordUser = (user) => ({
  type: types.RESET_PASSWORD,
  payload: user
})

const resetUser = () => ({ type: types.RESET_USER })

const deleteUser = () => ({ type: types.DELETE_USER })

const listUserRoles = (roles) => ({
  type: types.LIST_USER_ROLES,
  payload: roles
})

const listUserPermissions = (permissions) => ({
  type: types.LIST_USER_PERMISSIONS,
  payload: permissions
})

const assignUserRole = (role) => ({
  type: types.ASSIGN_ROLE,
  payload: role
})

const removeUserRole = (value) => ({
  type: types.REMOVE_ROLE,
  value
})

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_USERS:
      return {
        ...state,
        users: action.payload
      }
    case types.LOAD_USER:
      return {
        ...state,
        profile: action.payload
      }
    case types.LOAD_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload
      }
    case types.ADD_USER:
      return {
        ...state,
        profile: action.payload
      }
    case types.UPDATE_USER:
      return {
        ...state,
        profile: action.payload
      }
    case types.RESET_PASSWORD:
      return {
        ...state,
        profile: action.payload
      }
    case types.RESET_USER:
      return {
        ...state,
        profile: null,
        subscription: null
      }
    case types.LIST_USER_ROLES:
      return {
        ...state,
        roles: action.payload
      }
    case types.LIST_USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      }
    case types.ASSIGN_ROLE:
      return {
        ...state,
        roles: [...state.roles, action.payload]
      }
    case types.REMOVE_ROLE:
      return {
        ...state,
        roles: state.roles.filter((role) => role.attributes.roleId !== action.value )
      }
    default:
      return state
  }
}

export {
  listUsers,
  loadUser,
  loadUserSubscription,
  addUser,
  updateUser,
  resetPasswordUser,
  deleteUser,
  resetUser,
  userReducer,
  listUserRoles,
  listUserPermissions,
  assignUserRole,
  removeUserRole
}
