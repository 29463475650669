import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Input = React.forwardRef(({
  className, label, name, placeholder, value, type, onChange, hideLabel, shrinkInput
}, ref) => {
  const [formType, setFormType] = useState(type)
  const [passwordShown, setPasswordShown] = useState(false)
  const [icon, setIcon] = useState('eye')

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  }

  useEffect(() => {
    if (type === 'password') {
      setFormType(passwordShown ? 'text' : 'password')
      setIcon(passwordShown ? 'eye' : 'eye-slash')
    }
  }, [passwordShown, type])

  return (
    <div className="form-el-wrapper">
      <label className="form-label" style={hideLabel ? { display: 'none' } : { display: 'inline-block' }}>{label}</label>
      <div className="form-input-wrapper">
        <input
          className={className}
          placeholder={placeholder}
          name={name}
          value={value}
          type={formType}
          onChange={onChange}
          ref={ref}
          style={shrinkInput ? { width: '90%' } : { width: '100%' }}
        />
        {type === 'password' && (
          <FontAwesomeIcon icon={icon} className="form-pass-icon" onClick={togglePasswordVisiblity} />
        )}
      </div>
    </div>
  )
})

Input.defaultProps = {
  className: '',
  label: '',
  name: '',
  placeholder: '',
  value: '',
  type: '',
  hideLabel: false,
  shrinkInput: false,
  onChange: () => {}
}

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  shrinkInput: PropTypes.bool,
  onChange: PropTypes.func
}

export default Input
