const types = {
  LIST_PLANS: 'LIST_PLANS',
  LOAD_PLAN: 'LOAD_PLAN',
  RESET_LOADED_PLAN: 'RESET_LOADED_PLAN',
  CREATE_PLAN_ID: 'CREATE_PLAN_ID',
  RESET_PLAN_ID: 'RESET_PLAN_ID'
}

const intializedPlan = {
  name: '',
  id: '',
  interval: '',
  currency: '',
  prices: [],
  adFree: '',
  createdAt: '',
  updatedAt: ''
}

const initialState = {
  plans: {
    records: [],
    planTotal: 0
  },
  plan: intializedPlan,
  planId: ''
}

const listPlans = (plans) => ({
  type: types.LIST_PLANS,
  payload: plans
})

const loadPlan = (plan) => ({
  type: types.LOAD_PLAN,
  payload: plan
})

const resetLoadedPlan = () => ({
  type: types.RESET_LOADED_PLAN,
  payload: intializedPlan
})

const createPlanId = (planId) => ({
  type: types.CREATE_PLAN_ID,
  payload: planId
})

const resetPlanId = () => ({
  type: types.RESET_PLAN_ID,
  payload: ''
})

const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_PLANS:
      return {
        ...state,
        plans: {
          records: action.payload.data,
          planTotal: action.payload.meta.total
        }
      }
    case types.LOAD_PLAN:
      return {
        ...state,
        plan: action.payload
      }
    case types.RESET_LOADED_PLAN:
      return {
        ...state,
        plan: action.payload
      }
    case types.CREATE_PLAN_ID:
      return {
        ...state,
        planId: action.payload
      }
    case types.RESET_PLAN_ID:
      return {
        ...state,
        planId: action.payload
      }
    default:
      return state
  }
}

export {
  listPlans,
  loadPlan,
  resetLoadedPlan,
  createPlanId,
  resetPlanId,
  planReducer
}
