import React from 'react'
import PropTypes from 'prop-types'

const UnionArrows = ({ sortDirection }) => (
  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" title="sort">
    <path d="M7.46434 5.25L4.00023 0L0.536133 5.25H7.46434Z" fill={sortDirection === 'asc' ? '#007BFF' : '#343A40'} data-testid="union-arrow-up" />
    <path d="M0.536133 10.75L4.00023 16L7.46434 10.75L0.536133 10.75Z" fill={sortDirection === 'desc' ? '#007BFF' : '#343A40'} data-testid="union-arrow-down" />
  </svg>
)

UnionArrows.defaultProps = {
  sortDirection: null
}

UnionArrows.propTypes = {
  sortDirection: PropTypes.string
}

export default UnionArrows
