import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@mui/material/Tooltip'

import style from './style.module.scss'

const CloseButton = ({ handleClick }) => (
  <Tooltip
    title="Close"
    arrow
  >
    <button className={style.button} type="button" onClick={handleClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#6C757D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </button>
  </Tooltip>
)

CloseButton.propTypes = {
  handleClick: PropTypes.func.isRequired
}

export default CloseButton
