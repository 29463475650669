import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTimes, faLongArrowAltLeft, faEye, faEyeSlash,
  faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight,
  faEdit, faPlus, faTrashAlt, faSearch, faDownload
} from '@fortawesome/free-solid-svg-icons'

const initFontAwesome = () => {
  library.add(
    faTimes,
    faLongArrowAltLeft,
    faEye,
    faEyeSlash,
    faAngleDoubleLeft,
    faAngleLeft,
    faAngleDoubleRight,
    faAngleRight,
    faEdit,
    faPlus,
    faTrashAlt,
    faSearch,
    faDownload
  )
}

export default initFontAwesome
