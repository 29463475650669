const types = {
  LIST_ENTERPRISES: 'LIST_ENTERPRISES',
  LOAD_ENTERPRISE: 'LOAD_ENTERPRISE',
  ADD_ENTERPRISE: 'ADD_ENTERPRISE',
  UPDATE_ENTERPRISE: 'UPDATE_ENTERPRISE',
  RESET_ENTERPRISE: 'RESET_ENTERPRISE',
  LOAD_ENTERPRISE_SUBSCRIPTION: 'LOAD_ENTERPRISE_SUBSCRIPTION',
  LOAD_ENTERPRISE_ACCESS: 'LOAD_ENTERPRISE_ACCESS',
  UPDATE_ACCESS: 'UPDATE_ACCESS',
  UPDATE_ENTERPRISE_ACCESS: 'UPDATE_ENTERPRISE_ACCESS',
  RESET_ENTERPRISE_ACCESS: 'RESET_ENTERPRISE_ACCESS',
  RESET_ENTERPRISE_SUBSCRIPTION: 'RESET_ENTERPRISE_SUBSCRIPTION'
}

const initialState = {
  enterprises: {
    records: [],
    enterpriseTotal: 0
  },
  enterprise: {
    id: '',
    ownerId: '',
    type: '',
    name: '',
    code: '',
    connection: '',
    seats: '',
    domains: '',
    hubSpotId: '',
    createdAt: '',
    updatedAt: '',
    users: []
  },
  subscription: {
    id: '',
    status: '',
    autoRenew: '',
    origin: '',
    startsAt: '',
    endsAt: '',
    createdAt: '',
    updatedAt: ''
  },
  access: {
    id: '',
    provider: '',
    startsAt: '',
    createdAt: '',
    endsAt: '',
    updatedAt: ''
  }
}

const listEnterprises = (enterprises) => ({
  type: types.LIST_ENTERPRISES,
  payload: enterprises
})

const loadEnterprise = (enterprise) => ({
  type: types.LOAD_ENTERPRISE,
  payload: enterprise
})

const loadEnterpriseSubscription = (subscription) => ({
  type: types.LOAD_ENTERPRISE_SUBSCRIPTION,
  payload: subscription
})

const loadEnterpriseAccess = (access) => ({
  type: types.LOAD_ENTERPRISE_ACCESS,
  payload: access
})

const addEnterprise = (enterprise) => ({
  type: types.ADD_ENTERPRISE,
  payload: enterprise
})

const updateEnterprise = (enterprise) => ({
  type: types.LOAD_ENTERPRISE,
  payload: enterprise
})

const updateAccess = (access) => ({
  type: types.UPDATE_ENTERPRISE_ACCESS,
  payload: access
})

const resetEnterprise = () => ({ type: types.RESET_ENTERPRISE })

const resetEnterpriseAccess = () => ({ type: types.RESET_ENTERPRISE_ACCESS })

const resetEnterpriseSubscription = () => ({ type: types.RESET_ENTERPRISE_SUBSCRIPTION })

const enterpriseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_ENTERPRISES:
      return {
        ...state,
        enterprises: {
          ...state.enterprises,
          records: action.payload.data,
          enterpriseTotal: action.payload.meta.total
        }
      }
    case types.LOAD_ENTERPRISE_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload
      }
    case types.ADD_ENTERPRISE:
    case types.UPDATE_ENTERPRISE:
    case types.LOAD_ENTERPRISE:
      return {
        ...state,
        enterprise: action.payload
      }
    case types.RESET_ENTERPRISE:
      return {
        ...state,
        enterprise: null
      }
    case types.LOAD_ENTERPRISE_ACCESS:
    case types.UPDATE_ENTERPRISE_ACCESS:
      return {
        ...state,
        access: action.payload
      }
    case types.RESET_ENTERPRISE_ACCESS:
      return {
        ...state,
        access: null
      }
    case types.RESET_ENTERPRISE_SUBSCRIPTION:
      return {
        ...state,
        subscription: null
      }
    default:
      return state
  }
}

export {
  listEnterprises,
  loadEnterprise,
  loadEnterpriseSubscription,
  loadEnterpriseAccess,
  addEnterprise,
  updateEnterprise,
  resetEnterprise,
  resetEnterpriseAccess,
  resetEnterpriseSubscription,
  enterpriseReducer,
  updateAccess
}
