import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Button, Alert } from 'reactstrap'
import { useSelector } from 'react-redux'

import BIArrow from '../assets/BI-Arrow-Blue.svg'

const HomePage = () => {
  const { state } = useLocation()
  const history = useHistory()
  const { actions } = useSelector((appState) => appState.userScope)

  useEffect(() => {
    if (state?.email) {
      setTimeout(() => history.replace(), 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <div className="homepage">

      {state?.email && <Alert color="success">{`User ${state.email} is deleted!`}</Alert>}
      <div className="homepage-header-container">
        <h1>Welcome</h1>
        <p>To The User Management Portal</p>
      </div>

      <ul className="homepage-list">
        {actions.has('createUser') && (
          <li className="homepage-item">
            <img className="bi-arrow-icon" src={BIArrow} alt="arrow" />
            <p>To create a new user, click</p>
            <Button
              className="homepage-item-btn"
              type="button"
              onClick={() => history.push('/users/addnew')}
            >
              Add User
            </Button>
          </li>
        )}

        <li className="homepage-item">
          <img className="bi-arrow-icon" src={BIArrow} alt="arrow" />
          <p>To manage individual user, access user profile via search email in menu bar, or click
          </p>
          <Button
            className="homepage-item-btn"
            type="button"
            onClick={() => history.push('/s/users')}
          >
            Advanced Search
          </Button>
        </li>

        <li className="homepage-item">
          <img className="bi-arrow-icon" src={BIArrow} alt="arrow" />
          <p>To view and manage enterprises, click</p>
          <Button
            className="homepage-item-btn"
            type="button"
            onClick={() => history.push('/enterprises')}
          >
            Enterprises
          </Button>
        </li>

        <li className="homepage-item">
          <img className="bi-arrow-icon" src={BIArrow} alt="arrow" />
          <p>To view and manage plans, click</p>
          <Button
            className="homepage-item-btn"
            type="button"
            onClick={() => history.push('/plans')}
          >
            Plans
          </Button>
        </li>

      </ul>
    </div>
  )
}

export default HomePage
