const types = {
  SET_API_ERROR_MESSAGE: 'SET_API_ERROR_MESSAGE',
  SET_API_SUCCESS_MESSAGE: 'SET_API_SUCCESS_MESSAGE',
  SET_MODAL_DETAIL_MESSAGE: 'SET_MODAL_DETAIL_MESSAGE',
  SET_MODAL_CONFIRM_MESSAGE: 'SET_MODAL_CONFIRM_MESSAGE',
  RESET_ALL_MESSAGES: 'RESET_ALL_MESSAGES',
  SET_IS_MODAL: 'SET_IS_MODAL'
}

const initialState = {
  apiMessage: {
    apiError: '',
    apiSuccess: ''
  },
  modalMessage: {
    modalDetail: '',
    modalConfirm: ''
  },
  isModal: false
}

const setApiErrorMsg = (message) => ({
  type: types.SET_API_ERROR_MESSAGE,
  payload: message
})

const setApiSuccessMsg = (message) => ({
  type: types.SET_API_SUCCESS_MESSAGE,
  payload: message
})

const setModalDetailMsg = (message) => ({
  type: types.SET_MODAL_DETAIL_MESSAGE,
  payload: message
})

const setModalConfirmMsg = (message) => ({
  type: types.SET_MODAL_CONFIRM_MESSAGE,
  payload: message
})

const setIsModal = (value) => ({
  type: types.SET_IS_MODAL,
  payload: value
})

const resetAllMessages = () => ({ type: types.RESET_ALL_MESSAGES })

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_API_ERROR_MESSAGE:
      return {
        ...state,
        apiMessage: {
          ...state.apiMessage,
          apiError: action.payload
        }
      }
    case types.SET_API_SUCCESS_MESSAGE:
      return {
        ...state,
        apiMessage: {
          ...state.apiMessage,
          apiSuccess: action.payload
        }
      }
    case types.SET_MODAL_DETAIL_MESSAGE:
      return {
        ...state,
        modalMessage: {
          ...state.modalMessage,
          modalDetail: action.payload
        }
      }
    case types.SET_MODAL_CONFIRM_MESSAGE:
      return {
        ...state,
        modalMessage: {
          ...state.modalMessage,
          modalConfirm: action.payload
        }
      }
    case types.SET_IS_MODAL:
      return {
        ...state,
        isModal: action.payload
      }
    case types.RESET_ALL_MESSAGES:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}

export {
  setApiErrorMsg,
  setApiSuccessMsg,
  setModalDetailMsg,
  setModalConfirmMsg,
  setIsModal,
  resetAllMessages,
  notificationReducer
}
