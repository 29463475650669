import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalBody } from 'reactstrap'
import _ from 'lodash'

import { displayDate } from '../../../utils/format'

import style from './style.module.scss'

import CopyButton from '../../Shared/CopyButton'
import CloseButton from '../../Shared/CloseButton/index'

const DetailsModal = ({ isOpen, onDismissModal, plan }) => {
  const [copiedPlan, setCopiedPlan] = useState(false)

  const handleCopyClick = () => {
    setCopiedPlan(true)
    navigator.clipboard.writeText(plan.id)
  }

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={() => {
        onDismissModal()
        setCopiedPlan(false)
      }}
      cssModule={{ 'modal-content': style.modal }}
    >
      <ModalBody className={style.modalBody}>
        <div className={style.modalBodyHeader}>
          {plan.name}
          <CloseButton handleClick={
          () => {
            onDismissModal()
            setCopiedPlan(false)
          }
        }
          />
        </div>

        <div className={style.topRow}>
          <span>ID</span>
          <span>{plan.id}</span>
          <CopyButton
            isSelected={copiedPlan}
            handleClick={handleCopyClick}
          />
        </div>

        <div className={style.modalRow}>
          <span>Interval</span>
          <span>{_.capitalize(plan.interval) || 'N/A'}</span>
        </div>

        <div className={style.modalRow}>
          <span>Currency</span>
          <span>{plan.currency.toUpperCase() || 'N/A'}</span>
        </div>

        {plan.prices.map(({ number, type, amount }) => (
          <div key={number}>
            <div className={style.modalRow}>
              <span>Price {number} Type</span>
              <span>{_.capitalize(type)}</span>
            </div>

            <div className={style.modalRow}>
              <span>Price {number} Amount</span>
              <span>{amount}</span>
            </div>
          </div>
        ))}

        <div className={style.modalRow}>
          <span>Ad-Free</span>
          <span>{plan.adFree === 'true' ? 'Yes' : 'No'}</span>
        </div>

        <div className={style.modalRow}>
          <span>Created At</span>
          <span>{displayDate(new Date(plan.createdAt))}</span>
        </div>

        <div className={style.modalRow}>
          <span>Updated At</span>
          <span>{displayDate(new Date(plan.updatedAt))}</span>
        </div>

      </ModalBody>
    </Modal>
  )
}

DetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismissModal: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    interval: PropTypes.string,
    currency: PropTypes.string,
    prices: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.number,
      type: PropTypes.string,
      amount: PropTypes.string
    })),
    adFree: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string
  }).isRequired
}

export default DetailsModal
