const types = {
  LIST_ROLES: 'LIST_ROLES'
}

const initialState = {
  roles: []
}

const listRoles = (roleData) => ({
  type: types.LIST_ROLES,
  payload: roleData
})

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_ROLES:
      return {
        ...state,
        roles: action.payload
      }
    default:
      return state
  }
}

export {
  listRoles,
  roleReducer
}
