import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'

const NotFound = () => {
  const history = useHistory()

  return (
    <div className="page-not-found">
      <h1 className="page-error-header">Whoops!</h1>
      <p className="page-error-body">This page does not exist!</p>
      <Button color="secondary" className="page-error-btn" onClick={() => history.push('/')}>
        Go Home{' '}
      </Button>
    </div>
  )
}
export default NotFound
