import React from 'react'

const ServerError = () => (
  <div className="page-not-found">
    <h1 className="page-error-header">Whoops!</h1>
    <p className="page-error-body">
      Something went wrong.
      <br />
      (Please try again later)
    </p>
  </div>
)
export default ServerError
