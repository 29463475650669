import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { intervalMap } from '../Form/index'
import style from './style.module.scss'

const PlanDisplay = ({ dataToDisplay }) => (
  <div className={style.wrapper}>
    <div className={style.row}>
      <span>Plan name</span>
      {dataToDisplay.planName}
    </div>
    <div className={style.row}>
      <span>Plan provider</span>
      {_.capitalize(dataToDisplay.planProvider)}
    </div>
    <div className={style.row}>
      <span>Is plan Ad-free?</span>
      {dataToDisplay.adFree === 'true' ? 'Yes' : 'No'}
    </div>
    <div className={style.row}>
      <span>Plan interval</span>
      {_.capitalize(dataToDisplay.planInterval)}
    </div>
    <div className={style.row}>
      <span>Plan valid for</span>
      {`${intervalMap[dataToDisplay.planInterval]} Day${dataToDisplay.planInterval === 'daily' ? '' : 's'}`}
    </div>
    <div className={style.row}>
      <span>Plan currency</span>
      {dataToDisplay.planCurrency.toUpperCase()}
    </div>
    <div className={style.row}>
      <span>Price 1 amount</span>
      {dataToDisplay.price1Amount}
    </div>
    <div className={style.row}>
      <span>Price 1 type</span>
      {dataToDisplay.price2Amount ? 'Intro' : 'Standard'}
    </div>
    {dataToDisplay.price2Amount
      && (
        <>
          <div className={style.row}>
            <span>Price 2 amount</span>
            {dataToDisplay.price2Amount}
          </div>
          <div className={style.row}>
            <span>Price 2 type</span>
            Standard
          </div>
        </>

      )}
  </div>

)

PlanDisplay.propTypes = {
  dataToDisplay: PropTypes.shape({
    planName: PropTypes.string,
    planProvider: PropTypes.string,
    adFree: PropTypes.string,
    planInterval: PropTypes.string,
    planCurrency: PropTypes.string,
    price1Amount: PropTypes.string,
    price2Amount: PropTypes.string
  }).isRequired
}

export default PlanDisplay
