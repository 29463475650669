import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button, Modal, ModalBody, ModalFooter
} from 'reactstrap'
import PropTypes from 'prop-types'

import { setIsModal, resetAllMessages } from '../../redux/ducks/notification'

const AlertModal = ({ extraDetail, submit }) => {
  const dispatch = useDispatch()

  const {
    isModal,
    modalMessage: { modalDetail, modalConfirm }
  } = useSelector((state) => state.notification)

  const toggle = () => {
    dispatch([setIsModal(!isModal), resetAllMessages()])
  }

  return (
    <Modal isOpen={isModal} toggle={toggle} centered>
      <ModalBody>
        {modalDetail || modalConfirm}
        <br />
        {extraDetail}
      </ModalBody>
      <ModalFooter>
        {modalConfirm && (
          <Button color="danger" className="danger-btn danger-btn-margin" onClick={submit}>
            Yes
          </Button>
        )}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

AlertModal.defaultProps = {
  submit: () => {},
  extraDetail: ''
}

AlertModal.propTypes = {
  submit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  extraDetail: PropTypes.any
}

export default AlertModal
