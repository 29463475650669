const DATE_FORMAT = {
  month: 'short',
  day: 'numeric',
  year: 'numeric'
}

const DATE_TIME_FORMAT = {
  ...DATE_FORMAT,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
}

const syntaxHighlight = (json) => {
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')

  return json.replace(
    // eslint-disable-next-line no-useless-escape
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    (match) => {
      //eslint-disable-line
      let cls = 'number'
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key'
        } else {
          cls = 'string'
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean'
      } else if (/null/.test(match)) {
        cls = 'null'
      }
      return `<span class="${cls}">${match}</span>`
    }
  )
}

const setErrorTypeMap = (error, type) => {
  if (type) {
    const TYPE_MAP = {
      user: {
        minLength: 'Your password needs a minimum of 8 characters',
        maxLength: 'Your password needs a maximum of 25 characters',
        match: 'Your password does not match',
        pattern: 'Invalid Email'
      },
      enterprise: {
        pattern: 'Domains must be a space separated list of domain names without commas',
        min: 'Seats must be a positive integer'
      }
    }

    return TYPE_MAP[type] ? TYPE_MAP[type][error.type] : null
  }

  return null
}

const setFormError = (error, type) => {
  const ERROR_MAP = {
    required: 'This field is required',
    min: setErrorTypeMap(error, type) || 'Invalid Minimum',
    minLength: setErrorTypeMap(error, type) || 'Invalid minimum length',
    maxLength: setErrorTypeMap(error, type) || 'Invalid maximum length',
    pattern: setErrorTypeMap(error, type) || 'Invalid pattern',
    match: setErrorTypeMap(error, type) || 'Fields do not match'
  }

  return error ? ERROR_MAP[error.type] : null
}

const displayDateTime = (date) => date.toLocaleString('en-US', DATE_TIME_FORMAT)

const displayDate = (date) => date.toLocaleString('en-US', DATE_FORMAT)

const convertToDateTimeLocalString = (date) => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}`
}

const capitalizeFirstLetter = ([firstLetter, ...restOfWord]) => firstLetter.toUpperCase() + restOfWord.join('')

export {
  setFormError,
  syntaxHighlight,
  displayDateTime,
  capitalizeFirstLetter,
  displayDate,
  convertToDateTimeLocalString
}
