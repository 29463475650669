/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Alert } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../../components/Shared/Loading'
import EditEnterprise from '../../../components/Enterprise/EditEnterprise'

import { useAuth0 } from '../../../auth/auth0Provider'
import { useLoadEnterprise } from '../../../hooks/useEnterprise'
import { resetEnterprise } from '../../../redux/ducks/enterprise'
import { resetAllMessages } from '../../../redux/ducks/notification'
import { getEnterprise } from '../../../utils/api/skadi'
import { displayDateTime } from '../../../utils/format'
import { wait } from '../../../utils/helpers'
import EnterpriseMenu from '../../../components/Menu/EnterpriseMenu'

const EnterpriseProfile = () => {
  const { id } = useParams()
  const { user: auth0User } = useAuth0()
  const dispatch = useDispatch()

  const [requestLoadEnterprise] = useLoadEnterprise()
  const {
    enterprise: { enterprise },
    userScope: { actions },
    notification: {
      apiMessage: { apiSuccess, apiError },
      modalMessage: { modalDetail }
    }
  } = useSelector((state) => state)

  const [showEditName, setShowEditName] = useState(false)
  const [showEditSeats, setShowEditSeats] = useState(false)
  const [showEditDomains, setShowEditDomains] = useState(false)
  const [showAddUsers, setShowAddUsers] = useState(false)
  const [showDownloadUsers, setShowDownloadUsers] = useState(false)
  const [showEditHubSpotId, setShowEditHubSpotId] = useState(false)
  const [showAccess, setShowAccess] = useState(actions.has('listUserAccesses'))

  useEffect(() => {
    dispatch([resetEnterprise(), resetAllMessages()])
  }, [dispatch])

  useEffect(() => {
    requestLoadEnterprise(getEnterprise(auth0User, id))
  }, [auth0User, id, requestLoadEnterprise])

  useEffect(() => {
    (async () => {
      if (apiSuccess) {
        if (showEditName) {
          setShowEditName(false)
        } else if (showEditSeats) {
          setShowEditSeats(false)
        } else if (showEditDomains) {
          setShowEditDomains(false)
        } else if (showAddUsers) {
          setShowAddUsers(false)
        } else if (showDownloadUsers) {
          setShowDownloadUsers(false)
        } else if (showEditHubSpotId) {
          setShowEditHubSpotId(false)
        } else if (showAccess) {
          setShowAccess(false)
        }
        await wait(2000)
        dispatch(resetAllMessages())
      }
    })()
  }, [apiSuccess, dispatch, enterprise, showEditName, showEditSeats, showEditDomains, showAddUsers, showDownloadUsers, showEditHubSpotId, actions, showAccess])

  useEffect(() => {
    if (actions.has('listUserAccesses')) {
      setShowAccess(true)
    }
  }, [actions])

  if (!modalDetail && apiError) {
    return <Alert color="danger">{apiError}</Alert>
  }

  if (!enterprise) {
    return <Loading />
  }

  const handleEditName = () => {
    setShowEditName(true)
  }

  const handleEditSeats = () => {
    setShowEditSeats(true)
  }

  const handleEditDomains = () => {
    setShowEditDomains(true)
  }

  const handleAddUsers = () => {
    setShowAddUsers(true)
  }

  const handleDownloadUsers = () => {
    setShowDownloadUsers(true)
  }

  const handleEditHubSpotId = () => {
    setShowEditHubSpotId(true)
  }

  return (
    <Row className="detail-container">
      <EnterpriseMenu id={id} showAccess />
      <Col lg={8} className="detail-generation">

        <div className="detail-el">
          <h3>Name</h3>

          {showEditName ? <EditEnterprise setShowEditEnterprise={setShowEditName} isName />
            : (
              <>
                <p>{enterprise.name || '(empty)'}</p>

                {actions.has('updateEnterprise') && ( <FontAwesomeIcon className="inline-icon" icon="edit" onClick={handleEditName} />
                )}
              </>
            )}
        </div>

        <div className="detail-el">
          <h3>Code</h3>
          <p>{enterprise.code || '(empty)'}</p>
        </div>

        <div className="detail-el">
          <h3>Connection</h3>
          <p>{enterprise.connection || '(empty)'}</p>
        </div>

        <div className="detail-el">
          <h3>Seats Filled</h3>

          {showEditSeats ? <EditEnterprise setShowEditEnterprise={setShowEditSeats} isSeats />
            : (
              <>
                {/* eslint-disable-next-line no-nested-ternary */}
                <p>{enterprise.seats
                  ? enterprise.users
                    ? `${enterprise.users.length}/${enterprise.seats}`
                    : `0/${enterprise.seats}` : '(empty)'}
                </p>

                {actions.has('updateEnterprise') && ( <FontAwesomeIcon className="inline-icon" icon="edit" onClick={handleEditSeats} />
                )}
              </>
            )}
        </div>
        <div className="detail-el">
          <h3>Type</h3>
          <p>
            {enterprise.type}
          </p>
        </div>

        <div className="detail-el">
          <h3>Domains</h3>

          {showEditDomains ? <EditEnterprise setShowEditEnterprise={setShowEditDomains} isDomains />
            : (
              <>
                <p>{enterprise.domains ? enterprise.domains.join(', ') : '(empty)'}</p>

                {actions.has('updateEnterprise') && ( <FontAwesomeIcon className="inline-icon" icon="edit" onClick={handleEditDomains} />)}
              </>
            )}
        </div>

        <div className="detail-el">
          <h3>Add Users</h3>
          <p>&nbsp;</p>

          {showAddUsers ? <EditEnterprise setShowEditEnterprise={setShowAddUsers} isBulkEmail />
            : (
              <div>
                {actions.has('updateUserEnterprise') && ( <FontAwesomeIcon className="inline-icon" icon="edit" onClick={handleAddUsers} />)}
              </div>
            )}
        </div>

        {actions.has('updateUserEnterprise') && (
          <EditEnterprise setShowEditEnterprise={handleDownloadUsers} isDownloadUsers />
        )}

        <div className="detail-el">
          <h3>HubSpot ID</h3>

          {showEditHubSpotId ? <EditEnterprise setShowEditHubSpotId={setShowEditHubSpotId} isHubSpotId />
            : (
              <>
                <p>{enterprise.hubSpotId || '(empty)'}</p>

                {actions.has('updateEnterprise') && (<FontAwesomeIcon className="inline-icon" icon="edit" onClick={handleEditHubSpotId} />)}
              </>
            )}
        </div>

        <div className="detail-el">
          <h3>Updated At</h3>
          <p>{enterprise.updatedAt ? displayDateTime(new Date(enterprise.updatedAt)) : '(empty)'}</p>
        </div>

        <div className="detail-el">
          <h3>Created At</h3>
          <p>{enterprise.createdAt ? displayDateTime(new Date(enterprise.createdAt)) : '(empty)'}</p>
        </div>

      </Col>
    </Row>
  )
}
export default EnterpriseProfile
