import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@mui/material/Tooltip'

import style from './style.module.scss'

const ViewButton = ({ handleClick, isSelected }) => (
  <Tooltip
    title="View plan"
    arrow
  >
    <button
      type="button"
      className={style.button}
      onClick={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke={isSelected ? '#007BFF' : '#6C757D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-eye"
        title="View plan"
      >
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
        <circle cx="12" cy="12" r="3" />
      </svg>
    </button>
  </Tooltip>
)

ViewButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default ViewButton
