import React from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const GoBack = () => {
  const history = useHistory()
  return (
    <div role="button" tabIndex={0} className="page-go-back" onClick={() => history.goBack()}>
      <FontAwesomeIcon icon="long-arrow-alt-left" />
      <span>Go Back</span>
    </div>
  )
}

export default GoBack
